import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import "./ConfirmNavigation.less";

interface Props {
  when?: boolean | undefined;
  title?: string;
  message?: string;
  confirmLabel?: string;
  denyLabel?: string;
}

const ConfirmNavigation = ({
  when,
  title = "Are you sure you want to leave this page?",
  message = "Your unsaved changes will be lost.",
  confirmLabel = "Yes",
  denyLabel = "No"
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const handleNavigation = (nextLocation: any): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleNavigation} />
      <Modal
        centered
        closable={false}
        footer={[
          <Button
            key="confirm"
            onClick={() => {
              setModalVisible(false);
              setConfirmedNavigation(true);
            }}
          >
            {confirmLabel}
          </Button>,
          <Button
            key="deny"
            type="primary"
            onClick={() => setModalVisible(false)}
          >
            {denyLabel}
          </Button>
        ]}
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        width={420}
      >
        <div className="ConfirmNavigation">
          <div className="ConfirmNavigation-title">{title}</div>
          <p>{message}</p>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmNavigation;
