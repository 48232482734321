import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { Breadcrumb, Select } from "antd";
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder";
import ConfirmNavigation from "components/ConfirmNavigation/ConfirmNavigation";
import CustomerPicker from "components/CustomerPicker/CustomerPicker";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import Label from "components/Label/Label";
import Page from "components/Page/Page";
import PageHeader from "components/PageHeader/PageHeader";
import ReportCurrencyPicker from "components/Report/ReportCurrencyPicker/ReportCurrencyPicker";
import ReportTransfer from "components/ReportTransfer/ReportTransfer";
import { DATE_STORED } from "data/formats";
import {
  GET_REPORT,
  GET_REPORT_FIELDS,
  GET_REPORT_FIELD_VALUES
} from "data/queries";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import usePrevious from "hooks/usePrevious";
import { isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ApplicationContext,
  DateRangeType,
  Maybe,
  ReportDateType,
  ReportKeyType,
  RootGraphQlQuery
} from "types/graphql";
import {
  conditionsToReportConditions,
  convertFields,
  defaultReport,
  reportConditionsToConditions,
  reportParseReportDateQuery
} from "./Report.helpers";
import "./Report.less";
import ReportActions from "./ReportActions/ReportActions";

interface Props {
  id?: string;
}

const Report = ({ id }: Props) => {
  const localReport = useStoreState(state => state.reports.report);
  const isAdHoc = useStoreState(state => state.reports.reportAdHoc);
  const fields = useStoreState(state => state.reports.fields);
  const user = useStoreState(state => state.auth.user);
  const reportModified = useStoreState(state => state.reports.reportModified);
  const setLocalReport = useStoreActions(actions => actions.reports.setReport);
  const setFields = useStoreActions(actions => actions.reports.setFields);
  const setReportModified = useStoreActions(
    actions => actions.reports.setReportModified
  );
  const [conditionBuilderKey, setConditionBuilderKey] = useState(1);
  const client = useApolloClient();
  const previousCustomerOrCorporationKey = usePrevious(
    localReport.customerOrCorporationKey
  );

  const { loading: getReportLoading, data: getReportData } = useQuery<
    RootGraphQlQuery
  >(GET_REPORT, {
    variables: {
      id: id && parseInt(id)
    },
    skip: !id
  });

  useQuery<RootGraphQlQuery>(GET_REPORT_FIELDS, {
    variables: {
      keyType: localReport?.keyType,
      customerOrCorporationKey: localReport?.customerOrCorporationKey
    },
    skip: !localReport?.keyType || !localReport.customerOrCorporationKey,
    onCompleted: data => {
      setFields(data.reportFields);
    }
  });

  const remoteReport = getReportData?.reports?.[0];
  const isGrouped = localReport.groupByFields?.length !== 0;

  const handleCustomerPickerChange = (
    keyType: ReportKeyType,
    customerOrCorporationKey: number
  ) => {
    // Should we reset all of the attributes that are related to the customer?
    if (
      previousCustomerOrCorporationKey !== undefined &&
      customerOrCorporationKey !== previousCustomerOrCorporationKey
    ) {
      setLocalReport({
        ...localReport,
        keyType,
        customerOrCorporationKey,
        fields: undefined,
        sortByFields: undefined,
        groupByFields: undefined,
        conditions: undefined,
        schedule: undefined,
        sharedWith: undefined
      });

      // Increment the ConditionBuilder's key to force a re-render. This is necessary
      // because it is not a fully controlled component -- it merges incoming conditions
      // with the ones in its internal state.
      setConditionBuilderKey(conditionBuilderKey + 1);
    } else {
      setLocalReport({
        ...localReport,
        keyType,
        customerOrCorporationKey
      });
    }
  };

  // Whenever the remote report updates, override the local report.
  useEffect(() => {
    remoteReport && setLocalReport(remoteReport);
  }, [remoteReport, setLocalReport]);

  // Whenever the local or remote report changes, compare them.
  useEffect(() => {
    if (localReport && remoteReport) {
      const modified = !isEqual(localReport, remoteReport);
      if (modified !== reportModified) {
        setReportModified(modified);
      }
    }
  }, [localReport, remoteReport, reportModified, setReportModified]);

  // Reset to the default report and clear fields when navigating away.
  useEffect(() => {
    return () => {
      setLocalReport(defaultReport);
      setFields([]);
    };
  }, [setLocalReport, setFields]);

  // General functions.
  const getReportFieldValues = async (
    fieldId: string
  ): Promise<Maybe<string>[]> => {
    const parsedDates = reportParseReportDateQuery(
      localReport?.dateRangeType,
      localReport?.startDate,
      localReport?.endDate
    );

    const { data } = await client.query({
      query: GET_REPORT_FIELD_VALUES,
      variables: {
        fieldId: fieldId,
        keyType: localReport?.keyType,
        customerOrCorporationKey: localReport?.customerOrCorporationKey,
        dateType: localReport?.dateType,
        dateRange: {
          greaterThanOrEqual: parsedDates[0],
          lessThanOrEqual: parsedDates[1]
        }
      }
    });

    return data?.reportFieldValues?.result ?? [];
  };

  return (
    <Page>
      <div className="Report">
        <PageHeader
          title={
            !getReportLoading && isAdHoc
              ? "Ad-hoc report"
              : localReport?.name || ""
          }
          breadcrumbItems={
            <>
              <Breadcrumb.Item>
                <Link to="/reports">
                  <span>Reports</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{localReport?.name}</Breadcrumb.Item>
            </>
          }
          actions={<ReportActions />}
        />
        <div className="Report-contentWrapper">
          <div className="Report-customer">
            <Label text="Customer Or Corporation">
              <CustomerPicker
                context={ApplicationContext.Reporting}
                showCorporations
                customerOrCorporationKey={localReport?.customerOrCorporationKey}
                keyType={localReport?.keyType}
                defaultToFirstItem
                onChange={(keyType, customerOrCorporationKey) =>
                  handleCustomerPickerChange(keyType, customerOrCorporationKey)
                }
              />
            </Label>
          </div>
          <div className="Report-dateType">
            <Label
              text="Date Type"
              helpText="Specifies whether the date range is based on the ship date or entry date."
            >
              <Select
                value={localReport?.dateType}
                placeholder="Select date type..."
                onChange={dateType =>
                  setLocalReport({
                    ...localReport,
                    dateType
                  })
                }
              >
                <Select.Option value={ReportDateType.ShipDate}>
                  Ship date
                </Select.Option>
                <Select.Option value={ReportDateType.EntryDate}>
                  Entry date
                </Select.Option>

                {user?.hasPaidDateAccess && (
                  <Select.Option value={ReportDateType.PaidDate}>
                    Paid date
                  </Select.Option>
                )}
              </Select>
            </Label>
          </div>
          <div className="Report-dateRange">
            <Label
              text="Date Range"
              helpText={
                <>
                  <p>
                    Recent shipments may not appear, as{" "}
                    <strong>only closed shipments are included</strong>.
                  </p>
                  <p>
                    Choose a previous-week option to determine the date range
                    when the report runs. This is particularly useful when
                    scheduling a recurring report.
                  </p>
                </>
              }
            >
              <DateRangePicker
                rangeType={localReport?.dateRangeType || DateRangeType.Specific}
                startDate={
                  localReport?.startDate ||
                  moment()
                    .subtract(1, "week")
                    .format(DATE_STORED)
                }
                endDate={localReport?.endDate || moment().format(DATE_STORED)}
                onChange={(dateRangeType, startDate, endDate) =>
                  setLocalReport({
                    ...localReport,
                    dateRangeType,
                    startDate,
                    endDate
                  })
                }
              />
            </Label>
          </div>
          <div className="Report-fields">
            <Label
              text="Fields To Include"
              helpText="Included fields become columns in the report. You can also sort, group by, or apply conditions to these fields."
            >
              <ReportTransfer
                includedFieldValues={localReport?.fields}
                availableFields={fields}
                onChange={fields => {
                  const sortByFields = localReport?.sortByFields?.filter(f =>
                    fields.includes(f)
                  );
                  setLocalReport({ ...localReport, fields, sortByFields });
                }}
              />
              {isGrouped && (
                <span className="Report-fields-info">
                  * Hiding{" "}
                  {fields?.filter(field => !field.isSummarizable).length} fields
                  that can't be included in a summary report.
                </span>
              )}
            </Label>
          </div>
          <div className="Report-fieldOptions">
            <Label
              text="Show Currencies In"
              helpText="Customers may be billed in multiple currencies. The report can show these as originally charged, or convert everything to the customer's default currency."
            >
              <ReportCurrencyPicker />
            </Label>
            <Label
              text={`Sort Results By ${
                localReport.sortByFields?.length === 2 ? "(maximum 2)" : ""
              }`}
              helpText="Up to two columns can be selected to sort in ascending order."
            >
              <Select
                mode="multiple"
                disabled={!localReport?.fields}
                onChange={values =>
                  setLocalReport({
                    ...localReport,
                    sortByFields: values.slice(0, 2)
                  })
                }
                filterOption={(value, option) =>
                  option?.children.toUpperCase().includes(value.toUpperCase())
                }
                value={localReport?.sortByFields || []}
              >
                {fields
                  ?.filter(field => localReport?.fields?.includes(field.value))
                  .map(field => (
                    <Select.Option value={field.value} key={field.value}>
                      {field.label}
                    </Select.Option>
                  ))}
              </Select>
            </Label>
            <Label
              text="Group/Summarize By"
              helpText={
                <>
                  <p>
                    There may be many shipments with the same value for a given
                    field. Grouping combines those shipments together into a
                    single row, with a summary value for each field.
                  </p>
                  <p>
                    This allows for answering questions like "What was the total
                    amount paid by each division?"
                  </p>
                </>
              }
            >
              <Select
                mode="multiple"
                onChange={groupByFields =>
                  setLocalReport({ ...localReport, groupByFields })
                }
                value={localReport?.groupByFields || []}
                disabled={!fields}
                filterOption={(input, option) =>
                  option?.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {fields
                  ?.filter(field => !field.isSummarizable)
                  .map(field => (
                    <Select.Option value={field.value} key={field.value}>
                      {field.label}
                    </Select.Option>
                  ))}
              </Select>
            </Label>
          </div>
          <div className="Report-conditions">
            <Label
              text="Conditions To Apply"
              helpText={
                <>
                  <p>Use conditions to exclude shipments from the report.</p>
                  <p>
                    When using a group-by field,{" "}
                    <em>conditions are applied to the groups</em> and not the
                    individual shipments within each group.
                  </p>
                </>
              }
            >
              <ConditionBuilder
                key={conditionBuilderKey}
                fieldListValueRetrievalFunc={fieldId =>
                  getReportFieldValues(fieldId)
                }
                conditions={reportConditionsToConditions(
                  localReport?.conditions || []
                )}
                fields={convertFields(fields || [])}
                onConditionsChange={conditions =>
                  setLocalReport({
                    ...localReport,
                    conditions: conditionsToReportConditions(conditions)
                  })
                }
                showOperands
                allowRepeatedFields
              />
            </Label>
          </div>
        </div>
      </div>
      <ConfirmNavigation
        when={!isAdHoc && reportModified}
        title="Are you sure you want to leave?"
        message="This report has unsaved changes that will be lost."
        confirmLabel="Discard changes"
        denyLabel="Keep editing"
      />
    </Page>
  );
};

export default Report;
