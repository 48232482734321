import { Alert, Spin } from "antd";
import React from "react";
import "./LoadContent.less";

interface Props {
  loading: boolean;
  errorMessage?: string;
}

const LoadContent: React.FC<Props> = ({ loading, errorMessage, children }) => {
  if (errorMessage || loading) {
    return (
      <div className="LoadContent">
        {loading && <Spin size="large" />}
        {errorMessage && (
          <Alert
            className="HandleQuery-error"
            message="Something went wrong"
            description={errorMessage}
            type="error"
          />
        )}
      </div>
    );
  }

  return <>{children}</>;
};

export default LoadContent;
