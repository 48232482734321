import { DeleteOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, InputNumber, Select } from "antd";
import classNames from "classnames";
import BranchPicker from "components/BranchPicker/BranchPicker";
import CustomerPicker from "components/CustomerPicker/CustomerPicker";
import DivisionPicker from "components/DivisionPicker/DivisionPicker";
import NumberRange from "components/NumberRange/NumberRange";
import RegionPicker from "components/RegionPicker/RegionPicker";
import TextArrayPicker from "components/TextArrayPicker/TextArrayPicker";
import { DATE_DISPLAYED, DATE_STORED } from "data/formats";
import moment from "moment";
import React from "react";
import { Maybe, ReportConditionOperand } from "types/graphql";
import { Condition, Field } from "types/search";
import "./ConditionBuilderCondition.less";

interface Props {
  condition: Condition;
  fields: Field[];
  availableFields: Field[];
  onConditionUpdate: (updatedCondition: Condition) => void;
  onConditionDelete: (deletedCondition: Condition) => void;
  fieldValueListRetrievalFunc?: (fieldId: string) => Promise<Maybe<string>[]>;
  showOperand?: boolean;
  allowRepeatedFields?: boolean;
  isInvalid?: boolean;
}

const ConditionBuilderCondition: React.FC<Props> = ({
  condition,
  fields,
  availableFields,
  onConditionUpdate,
  onConditionDelete,
  fieldValueListRetrievalFunc,
  showOperand = false,
  allowRepeatedFields = false,
  isInvalid = false
}) => {
  const { Option } = Select;

  const handleFieldChange = (fieldId: string) => {
    const matchingField = fields.find(field => field.id === fieldId);

    onConditionUpdate({
      ...condition,
      field: fieldId,
      operator: matchingField?.operators?.[0].id,
      value: matchingField?.operators?.[0].defaultValue
    });
  };

  const handleOperatorChange = (operatorId: string, fieldId: string) => {
    const matchingField = fields.find(field => field.id === fieldId);
    const defaultValue = matchingField?.operators.find(
      operator => operator.id === operatorId
    )?.defaultValue;

    onConditionUpdate({
      ...condition,
      operator: operatorId,
      value: defaultValue
    });
  };

  const updateValue = (newValue: any) => {
    onConditionUpdate({ ...condition, value: newValue });
  };

  // Find the field that corresponds to this condition.
  const field = fields.find(field => field.id === condition.field) as Field;

  // Find the operator that corresponds to this condition.
  const operator =
    field &&
    field.operators &&
    field.operators.find(operator => operator.id === condition.operator);

  return (
    <div
      className={classNames("ConditionBuilderCondition", {
        "has-operand": showOperand
      })}
    >
      {showOperand && (
        <Select
          defaultValue={ReportConditionOperand.And}
          onChange={value =>
            onConditionUpdate({ ...condition, operand: value })
          }
          value={condition.operand}
        >
          <Option value={ReportConditionOperand.And}>and</Option>
          <Option value={ReportConditionOperand.Or}>or</Option>
        </Select>
      )}
      <Select
        className="ConditionBuilderCondition-field"
        placeholder="Select a field"
        value={condition.field}
        onChange={handleFieldChange}
        showSearch
        filterOption={(value, option) =>
          option?.children.toLowerCase().includes(value.toLowerCase())
        }
      >
        {fields.map(field => (
          <Option
            key={field.id}
            value={field.id}
            disabled={
              !allowRepeatedFields &&
              condition.field !== field.id &&
              !availableFields.includes(field)
            }
          >
            {field.name}
          </Option>
        ))}
      </Select>
      {condition.field && (
        <Select
          value={condition.operator}
          placeholder="Select an option"
          onChange={(value: string) =>
            condition.field && handleOperatorChange(value, condition.field)
          }
        >
          {field &&
            field.operators &&
            field.operators.map(operator => (
              <Option key={operator.id} value={operator.id}>
                {operator.name}
              </Option>
            ))}
        </Select>
      )}
      <div
        className={classNames("ConditionBuilderCondition-value", {
          "ant-form-item-has-error": isInvalid
        })}
      >
        {operator?.type === "text" && (
          <Input
            value={condition.value}
            onChange={event => updateValue(event.target.value)}
          />
        )}

        {operator?.type === "textArray" && (
          <TextArrayPicker
            valueRetrievalFunc={() => {
              return fieldValueListRetrievalFunc
                ? fieldValueListRetrievalFunc(field.id)
                : new Promise<Maybe<string>[]>(() => []);
            }}
            value={condition.value}
            onChange={(value: string[]) => updateValue(value)}
            noResultsMessage={`No values found for ${field.name} in the selected date range.`}
            placeholder=""
          />
        )}

        {operator?.type === "option" && (
          <Select
            defaultValue={condition.value as string}
            onChange={(value: string) => updateValue(value)}
          >
            {field.options &&
              field.options.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </Select>
        )}

        {operator?.type === "optionArray" && (
          <Select
            defaultValue={condition.value as string[]}
            mode="multiple"
            onChange={(value: string[]) => updateValue(value)}
          >
            {field.options &&
              field.options.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </Select>
        )}

        {operator?.type === "number" && (
          <InputNumber
            value={condition.value}
            type="number"
            onChange={value => updateValue(value)}
          />
        )}

        {operator?.type === "numberArray" && (
          <TextArrayPicker
            valueRetrievalFunc={() => {
              return fieldValueListRetrievalFunc
                ? fieldValueListRetrievalFunc(field.id)
                : new Promise<Maybe<string>[]>(() => []);
            }}
            value={condition.value}
            onChange={(value: string[]) => updateValue(value)}
            noResultsMessage={`No values found for ${field.name} in the selected date range.`}
            newItemValidator={(item: unknown) =>
              item !== "" && !isNaN(item as number)
            }
            placeholder="Number"
          />
        )}

        {operator?.type === "numberRange" && (
          <NumberRange
            min={condition.value?.[0]}
            max={condition.value?.[1]}
            onChange={(min, max) => updateValue([min, max])}
          ></NumberRange>
        )}

        {operator?.type === "boolean" && (
          <Select
            value={condition.value}
            onChange={(value: string) => updateValue(value)}
          >
            <Option value="true">true</Option>
            <Option value="false">false</Option>
          </Select>
        )}

        {operator?.type === "date" && (
          <DatePicker
            allowClear={false}
            value={condition.value ? moment(condition.value) : undefined}
            onChange={value => updateValue(value?.format(DATE_STORED))}
            format={DATE_DISPLAYED}
            disabledTime={() => ({
              disabledHours: () => [],
              disabledMinutes: () => [],
              disabledSeconds: () => []
            })}
          />
        )}

        {operator?.type === "dateArray" && (
          <TextArrayPicker
            valueRetrievalFunc={() => {
              return fieldValueListRetrievalFunc
                ? fieldValueListRetrievalFunc(field.id)
                : new Promise<Maybe<string>[]>(() => []);
            }}
            value={condition.value}
            onChange={(value: string[]) => updateValue(value)}
            noResultsMessage={`No values found for ${field.name} in the selected date range.`}
            newItemValidator={item =>
              item !== "" && RegExp(/^(\d{2})[/](\d{2})[/](\d{4})/).test(item)
            }
            placeholder="Date (MM/DD/YYYY)"
          />
        )}

        {operator?.type === "dateRange" && (
          <DatePicker.RangePicker
            allowClear={false}
            allowEmpty={[false, false]}
            value={[moment(condition.value?.[0]), moment(condition.value?.[1])]}
            onChange={value => {
              const start =
                value?.[0] && moment(value?.[0] || "").format(DATE_STORED);
              const end = value?.[1] && moment(value[1]).format(DATE_STORED);
              updateValue([start, end]);
            }}
            format={DATE_DISPLAYED}
          />
        )}

        {operator?.type === "customer" && (
          <CustomerPicker
            customerOrCorporationKey={Number(condition.value) || undefined}
            onChange={(type, key) => updateValue(key)}
          />
        )}

        {operator?.type === "division" && (
          <DivisionPicker
            division={condition.value}
            onChange={division => updateValue(division)}
          />
        )}

        {operator?.type === "region" && (
          <RegionPicker
            region={condition.value}
            onChange={region => updateValue(region)}
          />
        )}

        {operator?.type === "branch" && (
          <BranchPicker
            branch={condition.value}
            onChange={branch => updateValue(branch)}
          />
        )}
      </div>
      <Button
        size="small"
        icon={<DeleteOutlined />}
        onClick={() => onConditionDelete(condition)}
      />
    </div>
  );
};

export default ConditionBuilderCondition;
