import { Input } from "antd";
import Title from "antd/lib/typography/Title";
import PageHeader from "components/PageHeader/PageHeader";
import { useStoreState } from "hooks/storeHooks";
import React, { useState } from "react";
import ItemList, { MetaItem } from "./ItemList/ItemList";
import "./SavedList.less";

interface Props {
  title: string;
  actions?: React.ReactNode;
  items?: {
    title: string;
    author: string;
    authorId: string;
    link: string;
    date: string;
    metaItems?: MetaItem[];
  }[];
}

const SavedList = ({ title, actions, items }: Props) => {
  const userId = useStoreState(state => state.auth.user?.id)?.toString();
  const [filter, setFilter] = useState("");

  const filteredItems = items?.filter(i =>
    i.title.toLowerCase().includes(filter.toLowerCase())
  );

  const myItems = filteredItems?.filter(item => item.authorId === userId);
  const sharedItems = filteredItems?.filter(item => item.authorId !== userId);

  const actionsWithFilter = (
    <div className="SavedList-actions">
      <Input.Search
        className="SavedList-actions-filter"
        placeholder="Filter by name"
        value={filter}
        onChange={event => setFilter(event.target.value)}
      />
      {actions}
    </div>
  );

  return (
    <div className="SavedList">
      <div className="SavedList-header">
        <PageHeader title={title} actions={actionsWithFilter} />
      </div>
      <div className="SavedList-mine">
        <Title className="SavedList-sectionHeader" level={4}>
          Created by me
        </Title>
        {myItems && <ItemList items={myItems} />}
      </div>
      <div className="SavedList-shared">
        <Title className="SavedList-sectionHeader" level={4}>
          Shared with me
        </Title>
        {sharedItems && <ItemList items={sharedItems} />}
      </div>
    </div>
  );
};

export default SavedList;
