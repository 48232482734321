import { useMutation, useQuery } from "@apollo/react-hooks";
import { message } from "antd";
import LoadContent from "components/LoadContent/LoadContent";
import Search from "components/Search/Search";
import { UPDATE_SEARCH } from "data/mutations";
import { GET_SEARCH } from "data/queries";
import React, { useEffect, useState } from "react";
import SavedSearchContext from "state/SavedSearchContext";
import {
  RootGraphQlMutation,
  RootGraphQlQuery,
  SearchInput
} from "types/graphql";
import { Condition } from "types/search";
import baseSearches from "../../data/baseSearches";

interface Props {
  id: string;
}

const SavedSearch: React.FC<Props> = ({ id }) => {
  // Keep track of the conditions.
  const [conditions, setConditions] = useState<Condition[]>([]);

  // Retrieve the search.
  const { loading, error, data } = useQuery<RootGraphQlQuery>(GET_SEARCH, {
    variables: { id }
  });

  // Extract the search from the response.
  const search = data?.searches?.[0];

  // Find the corresponding base search.
  const baseSearch = baseSearches.find(
    baseSearch => baseSearch.id === search?.baseSearch
  )?.baseSearch;

  // Save changes to the search back to the server.
  const [updateSearch, { loading: saving }] = useMutation<
    RootGraphQlMutation,
    { input: SearchInput }
  >(UPDATE_SEARCH, {
    variables: {
      input: {
        id: search?.id || 0,
        name: search?.name || "",
        baseSearch: search?.baseSearch || "",
        query: baseSearch?.conditionsToQuery(conditions) || "",
        conditions: JSON.stringify(conditions)
      }
    },
    refetchQueries: [{ query: GET_SEARCH, variables: { id: search?.id } }],
    onCompleted: () => message.success("Changes saved."),
    onError: () => message.error("Unable to save changes.")
  });

  // Whenever the search changes, set the conditions.
  useEffect(() => {
    search?.conditions && setConditions(JSON.parse(search.conditions));
  }, [search]);

  // No search or base search? Something's gone wrong.
  let errorMessage;
  if (!loading && (!search || !baseSearch)) {
    errorMessage = `The saved search could not be found.`;
  }

  return (
    <LoadContent
      loading={loading}
      errorMessage={error?.message || errorMessage}
    >
      {baseSearch && search && (
        <SavedSearchContext.Provider value={{ baseSearch, search, conditions }}>
          <Search
            conditions={conditions}
            onConditionsChanged={conditions => setConditions(conditions)}
            onConditionsSaved={updateSearch}
            saving={saving}
          />
        </SavedSearchContext.Provider>
      )}
    </LoadContent>
  );
};

export default SavedSearch;
