import { SaveOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/react-hooks";
import { Breadcrumb, Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder";
import DeleteSearch from "components/DeleteSearch/DeleteSearch";
import DuplicateSearch from "components/DuplicateSearch/DuplicateSearch";
import Footer from "components/Footer/Footer";
import PageHeader from "components/PageHeader/PageHeader";
import Results from "components/Results/Results";
import gql from "graphql-tag";
import { useStoreState } from "hooks/storeHooks";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import SavedSearchContext from "state/SavedSearchContext";
import { Condition } from "types/search";
import "./Search.less";

interface Props {
  conditions: Condition[];
  onConditionsChanged: (conditions: Condition[]) => void;
  onConditionsSaved: () => void;
  saving: boolean;
}

const Search: React.FC<Props> = ({
  conditions,
  onConditionsChanged,
  onConditionsSaved,
  saving
}) => {
  const user = useStoreState(state => state.auth.user);
  const { baseSearch, search } = useContext(SavedSearchContext);
  const [
    loadResults,
    { loading: resultsLoading, data: resultsData }
  ] = useLazyQuery(gql(baseSearch.conditionsToQuery(conditions)));

  // Run the query whenever the search or conditions change.
  useEffect(() => {
    loadResults();
  }, [baseSearch, conditions, loadResults]);

  const userCanEdit = search.createdBy?.id === user?.id;

  let results: Record<string, string>[] = [];
  if (resultsData && !resultsLoading) {
    results = baseSearch.resultToRows(resultsData);
  }

  return (
    <div className="Search">
      <div className="Search-query">
        <div className="Search-query-contentWrapper">
          <PageHeader
            title={search.name}
            breadcrumbItems={
              <>
                <Breadcrumb.Item>
                  <Link to="/searches">
                    <span>Searches</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{search.name}</Breadcrumb.Item>
              </>
            }
            actions={
              <ButtonGroup>
                {userCanEdit && (
                  <Button
                    type="link"
                    icon={<SaveOutlined />}
                    onClick={onConditionsSaved}
                    disabled={!userCanEdit || saving}
                  >
                    {saving ? "Saving..." : "Save Changes"}
                  </Button>
                )}
                <DuplicateSearch
                  baseSearch={baseSearch}
                  conditions={conditions}
                />
                {userCanEdit && <DeleteSearch search={search} />}
              </ButtonGroup>
            }
          />
          <ConditionBuilder
            fields={baseSearch.fields}
            conditions={conditions}
            onConditionsChange={conditions => {
              onConditionsChanged(conditions);
            }}
          />
        </div>
      </div>
      <div className="Search-results">
        <div className="Search-results-contentWrapper">
          <Results rows={results} loading={resultsLoading} />
        </div>
      </div>
      <div className="Search-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Search;
