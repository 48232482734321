import Report from "components/Report/Report";
import SavedReports from "components/SavedReports/SavedReports";
import React from "react";
import { useParams } from "react-router-dom";

interface Props {}

const ReportsRoute: React.FC<Props> = () => {
  const { reportId } = useParams();

  if (!reportId) {
    return <SavedReports />;
  }

  return <Report id={reportId} />;
};

export default ReportsRoute;
