import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select } from "antd";
import useAppInsights from "hooks/useAppInsights";
import useCreateSearch from "hooks/useCreateSearch";
import React, { useState } from "react";

interface Props {}

const NewSearch: React.FC<Props> = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchType, setSearchType] = useState("closeout");
  const [track] = useAppInsights();
  const [createSearch, loading, error] = useCreateSearch({
    name: searchName,
    baseSearch: searchType
  });

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setModalOpen(true);
          track("New search button clicked");
        }}
      >
        New search
      </Button>
      <Modal
        visible={modalOpen}
        onOk={createSearch}
        onCancel={() => setModalOpen(false)}
        maskClosable={false}
        okText={loading ? "Creating search" : "Create search"}
        okButtonProps={{ disabled: !searchName, loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <p>
          <strong>Create new search</strong>
        </p>
        <p>
          This will create a new search with the name and type you specify.
          While anyone can view it, only you can make changes.
        </p>
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
          <Form.Item label="Name">
            <Input
              placeholder="Name of new search"
              onChange={event => setSearchName(event.target.value)}
              value={searchName}
              ref={input => input && input.focus()}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            extra="Determines which fields are available for this search."
          >
            <Select
              value={searchType}
              onChange={(type: string) => setSearchType(type)}
            >
              <Select.Option value="closeout">Closeout</Select.Option>
              <Select.Option value="remittance">Remittance</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        {error}
      </Modal>
    </>
  );
};

export default NewSearch;
