import { ColumnProps } from "antd/lib/table";
import { DATE_STORED } from "data/formats";
import { Guid } from "guid-typescript";
import moment from "moment";
import { RootGraphQlQuery } from "types/graphql";
import { BaseSearch, Condition, Field, ResultRow } from "types/search";
import {
  resultToRowsShared,
  sharedArgs,
  SharedColumns,
  sharedFields,
  sharedResultColumns
} from "./shared";

type Columns =
  | SharedColumns
  | "closeoutId"
  | "divisionDescription"
  | "customerOwner"
  | "paymentRequestKey";

const fields: Field[] = [
  ...sharedFields,
  {
    id: "customerCloseLevel",
    name: "Customer close level",
    operators: [
      {
        id: "isOneOf",
        name: "is one of",
        type: "optionArray"
      }
    ],
    options: [
      {
        label: "Customer",
        value: "Customer"
      },
      {
        label: "Division or region",
        value: "NonCustomer"
      }
    ]
  }
];

const defaultConditions: Condition[] = [
  {
    id: Guid.raw(),
    field: "date",
    operator: "between",
    value: [
      moment()
        .subtract(1, "week")
        .format(DATE_STORED),
      moment().format(DATE_STORED)
    ]
  },
  {
    id: Guid.raw(),
    field: "customerCloseLevel",
    operator: "isOneOf",
    value: ["Customer"]
  }
];

const conditionsToQuery = (
  conditions: Condition[],
  report: boolean = false
): string => {
  const args: string[] = sharedArgs(conditions, report);

  const customerCloseLevel = conditions.find(
    condition => condition.field === "customerCloseLevel"
  )?.value as string[];
  if (customerCloseLevel && customerCloseLevel.length > 0) {
    args.push(`customerCloseLevel: ["${customerCloseLevel.join('", "')}"]`);
  }

  return `
    query CloseoutQuery {
      payments(${args.join(", ")}) {
        number
        date
        amount {
          amount
        }
        branch {
          region {
            division {
              description
              customer {
                owner
                name
              }
            }
          }
        }
        invoice {
          number
        }
        closeoutId
        paymentRequests(limit: 1) {
          key
        }
      }
    }
  `;
};

const resultToRows = (result: RootGraphQlQuery): ResultRow<Columns>[] => {
  const payments = result.payments?.map(payment => ({
    ...resultToRowsShared(payment),
    closeoutId: payment.closeoutId ?? "",
    divisionDescription: payment.branch?.region?.division?.description ?? "",
    customerOwner: payment.branch?.region?.division?.customer?.owner ?? "",
    paymentRequestKey: payment.paymentRequests?.[0]?.key ?? ""
  }));

  return payments ?? [];
};

const resultColumns: ColumnProps<any>[] = [
  ...sharedResultColumns,
  {
    key: "closeoutId",
    dataIndex: "closeoutId",
    title: "Closeout ID"
  },
  {
    key: "divisionDescription",
    dataIndex: "divisionDescription",
    title: "Division description"
  },
  {
    key: "customerOwner",
    dataIndex: "customerOwner",
    title: "Customer owner"
  },
  {
    key: "paymentRequestKey",
    dataIndex: "paymentRequestKey",
    title: "Payment request key"
  }
];

const search: BaseSearch<Columns> = {
  name: "closeout",
  fields,
  defaultConditions,
  conditionsToQuery,
  resultToRows,
  resultColumns
};

export default search;
