import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Popover, Select, Spin } from "antd";
import CustomerPicker from "components/CustomerPicker/CustomerPicker";
import { GET_CUSTOMER_DIVISIONS } from "data/queries";
import React, { useEffect, useState } from "react";
import { Division, RootGraphQlQuery } from "types/graphql";
import "./DivisionPicker.less";

interface PopoverContentProps {
  onChange: (division: Division) => void;
}

const PopoverContent: React.FC<PopoverContentProps> = ({ onChange }) => {
  const [customerKey, setCustomerKey] = useState<number>();
  const [getDivisions, { loading, error, data }] = useLazyQuery<
    RootGraphQlQuery
  >(GET_CUSTOMER_DIVISIONS, {
    variables: { customerKey }
  });

  // Query for divisions whenever the customer is set.
  useEffect(() => {
    customerKey && getDivisions();
  }, [customerKey, getDivisions]);

  if (error) {
    return <div>Something went wrong.</div>;
  }

  const divisions = data?.customers?.[0].divisions;

  return (
    <div className="DivisionPicker-content">
      <CustomerPicker
        customerOrCorporationKey={customerKey}
        onChange={(type, key) => setCustomerKey(key)}
      />
      {divisions && (
        <Select
          showSearch
          placeholder="Select a division"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={(inputValue, option) => {
            const text = (option?.props.children as string).toUpperCase();
            return text.includes(inputValue.toUpperCase());
          }}
          onChange={divisionKey => {
            const selectedDivision = divisions?.find(
              division => division?.key === Number(divisionKey)
            );
            selectedDivision && onChange(selectedDivision);
          }}
          style={{ width: "100%" }}
        >
          {divisions.map(division => (
            <Select.Option
              key={division?.key}
              value={division?.key.toString() || ""}
            >
              {division?.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

interface Props {
  division?: Division;
  onChange: (division: Division) => void;
}

const DivisionPicker: React.FC<Props> = ({ division, onChange }) => {
  return (
    <div className="DivisionPicker">
      {!division && (
        <Popover
          content={
            <PopoverContent
              onChange={division => {
                onChange(division);
              }}
            />
          }
          title="Select a division"
          trigger="click"
          placement="bottom"
        >
          <Button>Select division...</Button>
        </Popover>
      )}
      {division && <div className="DivisionPicker-value">{division.name}</div>}
    </div>
  );
};

export default DivisionPicker;
