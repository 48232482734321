import { useQuery } from "@apollo/react-hooks";
import { Select, Spin } from "antd";
import { GET_CUSTOMERS } from "data/queries";
import { uniqBy, sortBy } from "lodash";
import React, { useCallback, useEffect } from "react";
import {
  ApplicationContext,
  ReportKeyType,
  RootGraphQlQuery
} from "types/graphql";

type CustomerOrCorporationOption = {
  label: string;
  value: string;
};

const getOptions = (data: RootGraphQlQuery) => {
  let options: CustomerOrCorporationOption[] = [];

  // Add all of the corporations.
  sortBy(
    uniqBy(
      data?.customers?.map(c => c.corporation),
      v => v?.key
    ).filter(c => c?.key),
    ['name'])
    .forEach(c => {
      c &&
        options.push({
          label: c.name,
          value: `${ReportKeyType.Corporation}_${c.key}`
        });
    });

  // Add all of the customers.
  data.customers?.forEach(c => {
    options.push({
      label: c.name || "",
      value: `${ReportKeyType.Customer}_${c.key}`
    });
  });

  return options;
};

interface Props {
  onChange: (type: ReportKeyType, key: number) => void;
  keyType?: ReportKeyType;
  customerOrCorporationKey?: number;
  showCorporations?: boolean;
  context?: ApplicationContext;
  defaultToFirstItem?: boolean;
}

const CustomerPicker: React.FC<Props> = ({
  onChange,
  keyType = ReportKeyType.Customer,
  customerOrCorporationKey,
  showCorporations,
  context = ApplicationContext.Ap,
  defaultToFirstItem = false
}) => {
  const { loading, error, data } = useQuery<RootGraphQlQuery>(GET_CUSTOMERS, {
    variables: {
      context
    }
  });

  const options = data && getOptions(data);
  const selectedValue =
    (keyType &&
      customerOrCorporationKey &&
      `${keyType}_${customerOrCorporationKey}`) ||
    undefined;
  const showingCorporations =
    showCorporations &&
    options?.find(o => o.value.startsWith(ReportKeyType.Corporation));

  const renderOptions = (type: ReportKeyType) => {
    return options
      ?.filter(option => option.value.startsWith(type))
      .map(option => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ));
  };

  const handleChange = useCallback(
    (value: string) => {
      const parts = value.split("_");
      if (parts[0] === ReportKeyType.Customer) {
        onChange(ReportKeyType.Customer, parseInt(parts[1]));
      } else {
        onChange(ReportKeyType.Corporation, parseInt(parts[1]));
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (options && defaultToFirstItem && !selectedValue) {
      handleChange(options?.[0].value);
    }
  }, [options, defaultToFirstItem, selectedValue, handleChange]);

  if (error) {
    return <div>Something went wrong.</div>;
  }

  return (
    <Select
      value={loading ? "" : selectedValue}
      showSearch
      placeholder={`Select customer${
        showingCorporations ? " or corporation" : ""
      }...`}
      notFoundContent={loading ? <Spin size="small" /> : null}
      filterOption={(inputValue, option) => {
        return (
          option?.children
            ?.toString()
            ?.toUpperCase()
            ?.includes(inputValue.toUpperCase()) ?? false
        );
      }}
      onChange={handleChange}
      style={{ width: "100%" }}
    >
      {showingCorporations ? (
        <>
          <Select.OptGroup label="Corporations">
            {renderOptions(ReportKeyType.Corporation)}
          </Select.OptGroup>
          <Select.OptGroup label="Customers">
            {renderOptions(ReportKeyType.Customer)}
          </Select.OptGroup>
        </>
      ) : (
        <>{renderOptions(ReportKeyType.Customer)}</>
      )}
    </Select>
  );
};

export default CustomerPicker;
