import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Popconfirm } from "antd";
import { DELETE_SEARCH } from "data/mutations";
import { GET_SEARCHES } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { RootGraphQlMutation, Search } from "types/graphql";

interface Props {
  search: Search;
}

const DeleteSearch: React.FC<Props> = ({ search }) => {
  const user = useStoreState(state => state.auth.user);
  const history = useHistory();
  const userCanDelete = search.createdBy?.id === user?.id;

  const [deleteSearch, { loading, error }] = useMutation<
    RootGraphQlMutation,
    {
      search: { id: number };
    }
  >(DELETE_SEARCH, {
    variables: {
      search: {
        id: search.id
      }
    },
    refetchQueries: [{ query: GET_SEARCHES }]
  });

  const handleDelete = async () => {
    await deleteSearch();
    history.push(`/searches`);
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete?"
      onConfirm={handleDelete}
      okText="Delete"
      cancelText="Cancel"
      okType="danger"
    >
      <span>
        <Button type="link" icon={<DeleteOutlined />} disabled={!userCanDelete}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
        {error && error.message}
      </span>
    </Popconfirm>
  );
};

export default DeleteSearch;
