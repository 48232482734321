import React from "react";
import { Search } from "types/graphql";
import { BaseSearch, Condition } from "../types/search";

interface SavedSearchState {
  baseSearch: BaseSearch<any>;
  search: Search;
  conditions: Condition[];
}

const SavedSearchContext = React.createContext<SavedSearchState>(
  // Everything is required, so there's no need for initial/default values.
  {} as SavedSearchState
);

export default SavedSearchContext;
