import { Breadcrumb } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import "./PageHeader.less";

interface Props {
  title: string;
  actions?: React.ReactNode;
  breadcrumbItems?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ title, actions, breadcrumbItems }) => {
  return (
    <div className="PageHeader">
      {breadcrumbItems && <Breadcrumb>{breadcrumbItems}</Breadcrumb>}
      <div className="PageHeader-titleActionsWrapper">
        <Title className="PageHeader-title" level={2}>
          {title}
        </Title>
        {actions && <div className="PageHeader-actions">{actions}</div>}
      </div>
    </div>
  );
};

export default PageHeader;
