import { Button, Empty, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Maybe } from "types/graphql";
import "./TextArrayPicker.less";

interface Props {
  valueRetrievalFunc: () => Promise<Maybe<string>[]>;
  value: string[];
  onChange: (value: string[]) => void;
  noResultsMessage: string;
  newItemValidator?: (item: string) => boolean;
  placeholder?: string;
}

const TextArrayPicker: React.FC<Props> = ({
  valueRetrievalFunc,
  value,
  onChange,
  noResultsMessage,
  newItemValidator = item => item !== "",
  placeholder = "Add another"
}) => {
  const [newItem, setNewItem] = useState("");
  const [availableItems, setAvailableItems] = useState<Maybe<string>[]>();

  useEffect(() => {
    const getValues = async () => {
      var response = await valueRetrievalFunc();
      setAvailableItems(response);
    };

    getValues();
  }, [valueRetrievalFunc]);

  const renderDropdown = (menu: React.ReactElement) => (
    <>
      {menu}
      <div className="UserPicker-newEmail">
        <Input
          value={newItem}
          onChange={event => setNewItem(event.target.value)}
          placeholder={placeholder}
          onKeyDown={event => {
            // The select component listens to all keydown events, so we need to
            // prevent it from responding to users pressing the backspace key.
            event.stopPropagation();
          }}
        />
        <Button
          type="primary"
          disabled={!newItemValidator(newItem)}
          htmlType="submit"
          onClick={() => {
            onChange([...value, newItem]);
            setNewItem("");
          }}
        >
          Add
        </Button>
      </div>
    </>
  );

  // ReportCondition values can't be null, so we use [""] to indicate that no value has been set.
  return (
    <Select
      className="ConditionBuilderCondition-textArrayTag"
      mode="multiple"
      onChange={onChange}
      value={value[0] !== "" ? value : undefined}
      dropdownRender={renderDropdown}
      notFoundContent={
        <div className="TextArrayPicker-noResults">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={null} />
          {noResultsMessage}
        </div>
      }
    >
      {availableItems?.map(item => (
        <Select.Option value={item!} key={item!}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TextArrayPicker;
