import AppInsightsService, {
  AppInsightsTrackFn
} from "services/AppInsightsService";
import { useStoreState } from "./storeHooks";

type AppInsightsHook = () => [AppInsightsTrackFn, AppInsightsService];

const useAppInsights: AppInsightsHook = () => {
  const appInsightsService = useStoreState(state => state.appInsightsService);

  return [appInsightsService.track, appInsightsService as AppInsightsService];
};

export default useAppInsights;
