import {
  BellOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import { Badge, Empty, Popover } from "antd";
import classNames from "classnames";
import { GET_BACKGROUND_JOBS } from "data/queries";
import usePrevious from "hooks/usePrevious";
import { isEqual, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BackgroundJobState, RootGraphQlQuery } from "types/graphql";
import "./Notifications.less";

const getDetails = (
  state: BackgroundJobState | null | undefined,
  created: string,
  modified: string
) => {
  const createdRelative = moment
    .utc(created)
    .local()
    .fromNow();

  const modifiedRelative = moment
    .utc(modified)
    .local()
    .fromNow();

  switch (state) {
    case BackgroundJobState.Pending:
      return {
        icon: (
          <LoadingOutlined
            spin
            className="Notifications-content-card-icon is-loading"
          />
        ),
        message: `Requested ${createdRelative}, working on it`
      };
    case BackgroundJobState.Complete:
      return {
        icon: (
          <CheckCircleOutlined className="Notifications-content-card-icon is-success" />
        ),
        message: `Completed ${modifiedRelative}, check your email`
      };
    case BackgroundJobState.Failed:
    case BackgroundJobState.Unknown:
    default:
      return {
        icon: (
          <CloseCircleOutlined className="Notifications-content-card-icon is-error" />
        ),
        message: `Failed ${modifiedRelative}, please try again`
      };
  }
};

interface Props {}

const Notifications = (props: Props) => {
  const [bellRinging, setBellRinging] = useState(false);
  const { data, loading } = useQuery<RootGraphQlQuery>(GET_BACKGROUND_JOBS, {
    pollInterval: 20000
  });

  const jobs = orderBy(data?.backgroundJobs, "dateCreated", "desc");
  const previousJobs = usePrevious(jobs);

  // Ring the bell if the jobs have changed.
  useEffect(() => {
    if (!loading && !isEqual(jobs, previousJobs)) {
      setBellRinging(true);
      setTimeout(() => {
        setBellRinging(false);
      }, 500);
    }
  }, [jobs, previousJobs, loading]);

  const content = (
    <div className="Notifications-content">
      {jobs ? (
        jobs.map(job => {
          const { icon, message } = getDetails(
            job?.state,
            job?.dateCreated,
            job?.dateModified
          );

          return (
            <div key={job?.id} className="Notifications-content-card">
              {icon}
              <div className="Notifications-content-card-textWrapper">
                <span className="Notifications-content-card-title">
                  {job?.displayName}
                </span>
                <span className="Notifications-content-card-message">
                  {message}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="You're all caught up."
        />
      )}
    </div>
  );

  return (
    <div className="Notifications">
      {jobs.length > 0 && (
        <Popover
          placement="bottomRight"
          content={content}
          trigger="click"
          overlayClassName="Notifications-popover"
        >
          <Badge dot={jobs.length > 0}>
            <button
              className={classNames("Notifications-button", {
                "is-ringing": bellRinging
              })}
            >
              <BellOutlined />
            </button>
          </Badge>
        </Popover>
      )}
    </div>
  );
};

export default Notifications;
