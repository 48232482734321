declare global {
  interface Window {
    config: {
      IMPACT_API_PATH: string;
      IMPACT_AUTH_PATH: string;
      BUILD_ID: string;
      RELEASE_VERSION: string;
      RELEASE_INFORMATIONAL_VERSION: string;
    };
  }
}

const defaultConfig = {
  IMPACT_API_PATH: "",
  IMPACT_AUTH_PATH: "",
  BUILD_ID: "",
  RELEASE_VERSION: "",
  RELEASE_INFORMATIONAL_VERSION: ""
};
const config = window && window.config ? window.config : defaultConfig;

export const {
  IMPACT_API_PATH,
  IMPACT_AUTH_PATH,
  BUILD_ID,
  RELEASE_VERSION,
  RELEASE_INFORMATIONAL_VERSION
} = config;
