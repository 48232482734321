import SavedSearch from "components/SavedSearch/SavedSearch";
import SavedSearches from "components/SavedSearches/SavedSearches";
import React from "react";
import { useParams } from "react-router-dom";

interface Props {}

const SearchesRoute: React.FC<Props> = () => {
  const { searchId } = useParams();

  if (!searchId) {
    return <SavedSearches />;
  }

  return <SavedSearch id={searchId} />;
};

export default SearchesRoute;
