import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Popconfirm } from "antd";
import { DELETE_REPORT } from "data/mutations";
import { GET_REPORTS } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { RootGraphQlMutation } from "types/graphql";

const ReportDelete = () => {
  const report = useStoreState(state => state.reports.report);
  const history = useHistory();

  const [deleteSearch, { loading, error }] = useMutation<
    RootGraphQlMutation,
    {
      report: { reportId: number };
    }
  >(DELETE_REPORT, {
    variables: {
      report: {
        reportId: report?.reportId || 0
      }
    },
    refetchQueries: [{ query: GET_REPORTS }]
  });

  const handleDelete = async () => {
    await deleteSearch();
    history.push(`/reports`);
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete?"
      onConfirm={handleDelete}
      okText="Delete"
      cancelText="Cancel"
      okType="danger"
    >
      <span>
        <Button type="link" icon={<DeleteOutlined />}>
          {loading ? "Deleting..." : "Delete"}
        </Button>
        {error && error.message}
      </span>
    </Popconfirm>
  );
};

export default ReportDelete;
