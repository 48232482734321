import gql from "graphql-tag";

export const GET_SEARCH = gql`
  query GET_SEARCH($id: Int!) {
    searches(id: $id) {
      id
      name
      createdBy {
        id
      }
      baseSearch
      conditions
      query
    }
  }
`;

export const GET_SEARCHES = gql`
  query GET_SEARCHES {
    searches {
      id
      name
      dateModified
      createdBy {
        id
        displayName
      }
    }
  }
`;

export const GET_EXPORT = gql`
  query GET_EXPORT($query: String!) {
    exports(query: $query) {
      url
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GET_CUSTOMER($context: ApplicationContext!, $customerKey: Int!) {
    customers(context: $context, customerKey: $customerKey) {
      key
      name
      baseCurrency
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GET_CUSTOMERS($context: ApplicationContext!) {
    customers(context: $context) {
      key
      name
      baseCurrency
      corporation {
        key
        name
        baseCurrency
      }
    }
  }
`;

export const GET_CUSTOMER_DIVISIONS = gql`
  query GET_CUSTOMER_DIVISIONS($customerKey: Int!) {
    customers(context: AP, customerKey: $customerKey) {
      divisions {
        key
        name
      }
    }
  }
`;

export const GET_CUSTOMER_REGIONS = gql`
  query GET_CUSTOMER_REGIONS($customerKey: Int!) {
    customers(context: AP, customerKey: $customerKey) {
      divisions {
        key
        name
        regions {
          key
          name
        }
      }
    }
  }
`;

export const GET_CUSTOMER_BRANCHES = gql`
  query GET_CUSTOMER_BRANCHES($customerKey: Int!) {
    customers(context: AP, customerKey: $customerKey) {
      divisions {
        key
        name
        regions {
          key
          name
          branches {
            key
            name
          }
        }
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query GET_REPORTS {
    reports {
      reportId
      name
      dateModified
      schedule {
        cronSchedule
      }
      createdBy {
        id
        displayName
      }
    }
  }
`;

export const GET_REPORT = gql`
  query GET_REPORT($id: Int!) {
    reports(id: $id) {
      conditions {
        id
        fieldId
        operand
        operator
        value
      }
      currencyType
      currencyOverride
      customerOrCorporationKey
      dateRangeType
      dateType
      endDate
      fields
      groupByFields
      keyType
      name
      reportId
      sortByFields
      startDate
      createdBy {
        id
      }
      schedule {
        id
        cronSchedule
        recipients
        subject
        message
      }
      sharedWith {
        id
        email
      }
    }
  }
`;

export const GET_REPORT_FIELDS = gql`
  query GET_REPORT_FIELDS(
    $customerOrCorporationKey: Int!
    $keyType: ReportKeyType!
  ) {
    reportFields(
      customerOrCorporationKey: $customerOrCorporationKey
      keyType: $keyType
    ) {
      label
      value
      dataType
      isSummarizable
      specialtyType
    }
  }
`;

export const GET_REPORT_DOWNLOAD = gql`
  query GET_REPORT_DOWNLOAD($report: ReportInput!) {
    runReport(report: $report) {
      urls
      hasBeenQueued
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS($key: Int!, $keyType: ReportKeyType!) {
    users(customerOrCorporationKey: $key, keyType: $keyType) {
      id
      email
      displayName
    }
  }
`;

export const GET_REPORT_FIELD_VALUES = gql`
  query GET_REPORT_FIELD_VALUES(
    $fieldId: String!
    $keyType: ReportKeyType!
    $customerOrCorporationKey: Int!
    $dateType: ReportDateType!
    $dateRange: DateArgument!
  ) {
    reportFieldValues(
      fieldId: $fieldId
      keyType: $keyType
      customerOrCorporationKey: $customerOrCorporationKey
      dateType: $dateType
      dateRange: $dateRange
    ) {
      result
    }
  }
`;

export const GET_BACKGROUND_JOBS = gql`
  query GET_BACKGROUND_JOBS {
    backgroundJobs {
      id
      displayName
      state
      dateCreated
      dateModified
    }
  }
`;
