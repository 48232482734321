import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, Typography } from "antd";
import Footer from "components/Footer/Footer";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import "./Protected.less";

interface Props {
  children: React.ReactNode;
}

const Protected: React.FC<Props> = ({ children }) => {
  const user = useStoreState(state => state.auth.user);
  const signingIn = useStoreState(state => state.auth.signingIn);
  const error = useStoreState(state => state.auth.error);
  const signIn = useStoreActions(actions => actions.auth.signIn);

  const [username, setUsername] = useState(user?.username || "");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  var isOTPNeeded = false;
  // Clear the password once the user has signed in.
  useEffect(() => {
    if (user) {
      setPassword("");
    }
  }, [user]);

  // If there's no user, show a sign-in form.
  if (!user?.id) {
    return (
      <div className="Protected">
        <div className="Protected-contentWrapper">
          <form>
            <Typography.Title level={3}>IMPACT AdHoc Reporting</Typography.Title>
            <div className="Protected-field">
              <Input
                type="email"
                placeholder="Email"
                prefix={<UserOutlined />}
                disabled={signingIn}
                value={username}
                onChange={event => setUsername(event.target.value)}
              />
            </div>
            <div className="Protected-field">
              <Input.Password
                placeholder="Password"
                prefix={<LockOutlined />}
                disabled={signingIn}
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </div>
            {error && (error.message === "OTP sent successfully"
              || error.message === "OTP mismatch") ?
              (
                (isOTPNeeded = true) &&
                (<div className="Protected-field">
                  <Input.Password
                    placeholder="OTP"
                    prefix={<LockOutlined />}
                    disabled={signingIn}
                    value={otp}
                    onChange={event => setOTP(event.target.value)}
                  />
                </div>
                )
              ) : ""
            }
            <Button
              type="primary"
              htmlType="submit"
              onClick={e => {
                e.preventDefault();
                signIn({
                  username,
                  password,
                  otp
                });
                setOTP("");
              }}
              block
              loading={signingIn}
              disabled={!username || !password
                || (isOTPNeeded && !otp)}
            >
              {signingIn ? "Signing In..." : "Sign In"}
            </Button>
            {error && (
              <span className="Protected-error">
                {error.message === "Username or password is incorrect"
                  ? (
                    <>
                      {error.message}
                      <br />
                      <a href="https://nvision-application-web.azurewebsites.net/Account/ForgotPassword">
                        Forgot your password?
                      </a>
                    </>
                  ) : (
                    error.message
                  )}
              </span>
            )}
             <br />
            <div>              
              <p><strong> Copyright © 2004-{new Date().getFullYear()} All Rights Reserved. </strong> 
              <br />By accessing, loading, installing, or using any software technology solutions or services provided by nVision Global Technology Solutions, Inc., you agree to be bound by the <a
                href="https://corporate.nvisionglobal.com/end-user-license-agreement/"><u> Software License</u></a> and <a href="https://corporate.nvisionglobal.com/terms-of-use/">
                <u> Terms of Use</u>
                </a> for this site and all technology provided by nVision Global and affiliated companies.</p>
            </div>

          </form>
          
        </div>
        <Footer />
      </div>
    );
  }

  // If there is a user, render the children (no need to sign in).
  return <>{children}</>;
};
export default Protected;