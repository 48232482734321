import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import { Button } from "antd";
import Page from "components/Page/Page";
import { dayNames } from "components/Report/ReportSchedule/Scheduler";
import SavedList from "components/SavedList/SavedList";
import { GET_REPORTS } from "data/queries";
import React from "react";
import { Link } from "react-router-dom";
import { RootGraphQlQuery } from "types/graphql";

const readableCron = (cron: string) => {
  const [minutePart, hourPart, monthDayPart, , weekDayPart] = cron.split(" ");

  const getRepeats = () => {
    if (monthDayPart !== "*") {
      return `Monthly on day ${monthDayPart}`;
    } else if (weekDayPart !== "*") {
      const days = weekDayPart.split(",").map(n => parseInt(n));

      switch (days.length) {
        case 1:
          return dayNames[days[0]];
        case 2:
          return `${dayNames[days[0]]} and ${dayNames[days[1]]}`;
        default:
          let dayList = days.sort().map(day => dayNames[day]);
          let lastDay = dayList.pop();
          return `${dayList.join(", ")}, and ${lastDay}`;
      }
    } else {
      return "Every day";
    }
  };

  const getTime = () => {
    let hour = "";
    let period = "";
    let minute = minutePart.length === 1 ? `0${minutePart}` : minutePart;

    if (parseInt(hourPart) > 12) {
      hour = (parseInt(hourPart) - 12).toString();
      period = "PM";
    } else {
      hour = parseInt(hourPart).toString();
      period = "AM";
    }

    return `${hour}:${minute} ${period}`;
  };

  return `${getRepeats()} at ${getTime()}`;
};

interface Props {}

const SavedReports: React.FC<Props> = () => {
  const { data } = useQuery<RootGraphQlQuery>(GET_REPORTS);

  const reports = data?.reports;

  return (
    <Page>
      <SavedList
        title="Reports"
        actions={
          <Link to="/reports/new">
            <Button type="primary" icon={<PlusOutlined />}>
              New Report
            </Button>
          </Link>
        }
        items={reports?.map(report => ({
          title: report.name || "Unnamed report",
          author: report.createdBy?.displayName || "Unknown user",
          authorId: report.createdBy.id.toString(),
          link: `/reports/${report.reportId}`,
          date: report.dateModified,
          metaItems: report.schedule?.cronSchedule
            ? [
                {
                  key: "schedule",
                  icon: <CalendarOutlined />,
                  label: "Scheduled",
                  tooltip: readableCron(report.schedule?.cronSchedule)
                }
              ]
            : undefined
        }))}
      />
    </Page>
  );
};

export default SavedReports;
