import React from "react";
import "./Footer.less";
import logo from "./logo.svg";

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <div className="Footer">
      <div className="Footer-contentWrapper">
        <img src={logo} alt="nVision Global" />
      </div>
    </div>
  );
};

export default Footer;
