import { defaultReport, validReport } from "components/Report/Report.helpers";
import { Action, action, Computed, computed } from "easy-peasy";
import { Report, ReportField } from "types/graphql";

export interface ReportsModel {
  report: Partial<Report>;
  setReport: Action<ReportsModel, Partial<Report>>;
  fields: ReportField[];
  setFields: Action<ReportsModel, ReportField[]>;
  reportModified: boolean;
  setReportModified: Action<ReportsModel, boolean>;
  reportValid: Computed<ReportsModel, boolean>;
  reportAdHoc: Computed<ReportsModel, boolean>;
}

const reportsModel: ReportsModel = {
  report: defaultReport,
  setReport: action((state, payload) => {
    state.report = payload;
  }),
  fields: [],
  setFields: action((state, payload) => {
    state.fields = payload;
  }),
  reportModified: false,
  setReportModified: action((state, payload) => {
    state.reportModified = payload;
  }),
  reportValid: computed(state => validReport(state.report)),
  reportAdHoc: computed(state => !state.report?.reportId)
};

export default reportsModel;
