import { Button, Result } from "antd";
import Footer from "components/Footer/Footer";
import React from "react";
import { Link } from "react-router-dom";
import "./NotFoundRoute.less";

interface Props {}

const NotFoundRoute: React.FC<Props> = () => {
  return (
    <div className="NotFoundRoute">
      <div className="NotFoundRoute-message">
        <Result
          title="Page not found"
          status="error"
          extra={
            <Link to="/">
              <Button type="primary">Back to home</Button>
            </Link>
          }
        />
      </div>
      <div className="NotFoundRoute-footer">
        <Footer />
      </div>
    </div>
  );
};

export default NotFoundRoute;
