import HelpPopover from "components/HelpPopover/HelpPopover";
import React, { ReactNode, ReactText } from "react";
import "./Label.less";

interface Props {
  text: string;
  helpText?: ReactText | ReactNode;
}

const Label: React.FC<Props> = ({ text, helpText, children }) => {
  return (
    <div className="Label">
      <label className="Label-label">
        {text} {helpText && <HelpPopover text={helpText} />}
      </label>
      {children}
    </div>
  );
};

export default Label;
