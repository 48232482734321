import gql from "graphql-tag";

export const CREATE_SEARCH = gql`
  mutation CREATE_SEARCH($input: SearchInput!) {
    createSearch(search: $input) {
      id
    }
  }
`;

export const UPDATE_SEARCH = gql`
  mutation UPDATE_SEARCH($input: SearchInput!) {
    updateSearch(search: $input) {
      id
    }
  }
`;

export const DELETE_SEARCH = gql`
  mutation DELETE_SEARCH($search: DeleteSearchInput!) {
    deleteSearch(search: $search) {
      id
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation CREATE_REPORT($input: ReportInput!) {
    createReport(report: $input) {
      reportId
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UPDATE_REPORT($input: ReportInput!) {
    updateReport(report: $input) {
      reportId
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DELETE_REPORT($report: DeleteReportInput!) {
    deleteReport(report: $report) {
      reportId
    }
  }
`;
