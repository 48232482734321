import { useStoreState } from "hooks/storeHooks";
import usePermissions from "hooks/usePermissions";
import React from "react";
import ReportDelete from "../ReportDelete/ReportDelete";
import ReportDownload from "../ReportDownload/ReportDownload";
import ReportDuplicate from "../ReportDuplicate/ReportDuplicate";
import ReportSave from "../ReportSave/ReportSave";
import ReportSaveNew from "../ReportSaveNew/ReportSaveNew";
import ReportSchedule from "../ReportSchedule/ReportSchedule";
import ReportShare from "../ReportShare/ReportShare";

const ReportActions = () => {
  const localReport = useStoreState(state => state.reports.report);
  const isAdHoc = useStoreState(state => state.reports.reportAdHoc);
  const { canEditReport } = usePermissions();

  const hasEditPermissions = canEditReport(localReport);

  return (
    <>
      {(isAdHoc || hasEditPermissions) && <ReportSchedule />}
      {(isAdHoc || hasEditPermissions) && <ReportShare />}
      {hasEditPermissions && <ReportDelete />}
      {!isAdHoc && <ReportDuplicate />}
      {isAdHoc && <ReportSaveNew />}
      {hasEditPermissions && <ReportSave />}
      <ReportDownload />
    </>
  );
};

export default ReportActions;
