import {
  DownloadOutlined,
  MailOutlined,
  WarningFilled
} from "@ant-design/icons";
import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import { Button, message, Tooltip } from "antd";
import { GET_BACKGROUND_JOBS, GET_REPORT_DOWNLOAD } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import moment from "moment";
import React from "react";
import {
  BackgroundJob,
  BackgroundJobState,
  DateRangeType,
  RootGraphQlQuery
} from "types/graphql";
import { reportToReportInput } from "../Report.helpers";
import "./ReportDownload.less";

const ReportDownload = () => {
  const client = useApolloClient();
  const report = useStoreState(state => state.reports.report);
  const reportValid = useStoreState(state => state.reports.reportValid);
  const [downloadReport, { loading, error }] = useLazyQuery<RootGraphQlQuery>(
    GET_REPORT_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (data?.runReport.hasBeenQueued) {
          message.success(
            "You'll receive the report in your email as soon as it's ready."
          );

          // Add a new job for immediate display in the notifications area. This will
          // be replaced with the actual job from the server at the next poll interval.
          const currentJobs = client.cache.readQuery<RootGraphQlQuery>({
            query: GET_BACKGROUND_JOBS
          })?.backgroundJobs as BackgroundJob[];

          client.writeQuery<Partial<RootGraphQlQuery>>({
            query: GET_BACKGROUND_JOBS,
            data: {
              backgroundJobs: [
                {
                  __typename: "BackgroundJob",
                  id: "TEMP",
                  dateCreated: moment().utc(),
                  dateModified: moment().utc(),
                  state: BackgroundJobState.Pending,
                  displayName: report.name || "Ad-hoc report"
                },
                ...currentJobs
              ]
            }
          });
        } else {
          // If it's not queued, we'll always receive an array with a single URL.
          const url = data?.runReport?.urls[0];
          if (url && url !== "") {
            window.location.href = url;
          }
        }
      }
    }
  );

  let icon = <DownloadOutlined />;
  let label = "Download";
  let loadingLabel = "Downloading...";

  if (report?.dateRangeType === DateRangeType.Specific) {
    const dayRange = moment(report.endDate).diff(
      moment(report.startDate),
      "days"
    );
    if (dayRange > 90) {
      icon = <MailOutlined />;
      label = "Queue";
      loadingLabel = "Queuing...";
    }
  }

  return (
    <Tooltip
      title={
        error && (
          <>
            <WarningFilled /> An error occurred while requesting this report.
            Please try again.
          </>
        )
      }
      visible={!!error}
      placement="topRight"
      overlayClassName="ReportDownload-tooltip"
    >
      <Button
        type="primary"
        icon={icon}
        loading={loading}
        disabled={loading || !reportValid}
        onClick={() =>
          downloadReport({
            variables: { report: reportToReportInput(report) }
          })
        }
      >
        {loading ? loadingLabel : label}
      </Button>
    </Tooltip>
  );
};

export default ReportDownload;
