import AppInsightsService from "services/AppInsightsService";
import authModel, { AuthModel } from "./authModel";
import reportsModel, { ReportsModel } from "./reportsModel";

const appInsightsService = new AppInsightsService();

export interface AppModel {
  auth: AuthModel;
  reports: ReportsModel;
  appInsightsService: AppInsightsService;
}

const appModel: AppModel = {
  auth: authModel,
  reports: reportsModel,
  appInsightsService
};

export default appModel;
