import { Button, Result } from "antd";
import React, { Component } from "react";
import "./ErrorBoundary.less";

interface State {
  error: Error | null;
}

export default class ErrorBoundary extends Component<any, State> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: {}) {
    this.setState({ error });
  }
  render() {
    if (this.state.error) {
      return (
        <Result
          className="ErrorBoundary"
          status="error"
          title="Oh no!"
          subTitle="We've encountered an error, please try again."
          extra={
            <Button type="primary" href="/">
              Reload app
            </Button>
          }
        />
      );
    }
    return this.props.children;
  }
}
