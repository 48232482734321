import { SaveOutlined, UserOutlined } from "@ant-design/icons";
import { List, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import "./ItemList.less";

export type MetaItem = {
  key: string;
  icon: React.ReactNode;
  label?: string;
  tooltip?: string;
};

interface Props {
  items?: {
    title: string;
    author: string;
    link: string;
    date: string;
    metaItems?: MetaItem[];
  }[];
}

const ItemList = ({ items }: Props) => {
  items?.sort((a, b) => (a.date > b.date ? -1 : 1));

  return (
    <List
      itemLayout="horizontal"
      dataSource={items}
      split={true}
      locale={{
        emptyText: "No items found"
      }}
      renderItem={item => {
        const metadata: MetaItem[] = [
          {
            key: "author",
            icon: <UserOutlined />,
            label: item.author
          },
          {
            key: "date",
            icon: <SaveOutlined />,
            label: moment.utc(item.date).fromNow()
          },
          ...(item.metaItems ? item.metaItems : [])
        ];

        return (
          <List.Item>
            <Link to={item.link}>
              <List.Item.Meta
                title={item.title}
                description={metadata.map(item => (
                  <Tooltip key={item.key} title={item.tooltip}>
                    <span>
                      {item.icon}
                      {item.label && (
                        <span className="ItemList-label">{item.label}</span>
                      )}
                    </span>
                  </Tooltip>
                ))}
              />
            </Link>
          </List.Item>
        );
      }}
    />
  );
};

export default ItemList;
