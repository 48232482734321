import { ColumnProps } from "antd/lib/table";
import { DATE_STORED } from "data/formats";
import { Guid } from "guid-typescript";
import moment from "moment";
import { RootGraphQlQuery } from "types/graphql";
import { BaseSearch, Condition, Field, ResultRow } from "types/search";
import {
  resultToRowsShared,
  sharedArgs,
  SharedColumns,
  sharedFields,
  sharedResultColumns
} from "./shared";

type Columns = SharedColumns | "transportationProviderName";

const fields: Field[] = [
  ...sharedFields,
  {
    id: "fundingType",
    name: "Customer funding type",
    operators: [
      {
        id: "is",
        name: "is",
        type: "option"
      }
    ],
    options: [
      {
        label: "Funded",
        value: "FUNDED"
      },
      {
        label: "Non-funded",
        value: "NON_FUNDED"
      }
    ]
  }
];

const defaultConditions: Condition[] = [
  {
    id: Guid.raw(),
    field: "date",
    operator: "is",
    value: moment().format(DATE_STORED)
  },
  {
    id: Guid.raw(),
    field: "includedTypes",
    operator: "isOneOf",
    value: ["CHECK", "ACH"]
  }
];

const conditionsToQuery = (
  conditions: Condition[],
  report: boolean = false
): string => {
  const args: string[] = sharedArgs(conditions, report);

  const fundingType = conditions.find(
    condition => condition.field === "fundingType"
  )?.value;
  if (fundingType) {
    args.push(`fundingType: ${fundingType}`);
  }

  const query = `
    query RemittanceQuery {
      payments(${args.join(", ")}) {
        number
        date
        amount {
          amount
        }
        branch {
          region {
            division {
              customer {
                name
              }
            }
          }
        }
        invoice {
          number
        }
        shipments(limit: 1) {
          transportationProvider {
            name
          }
        }
      }
    }
  `;

  return query;
};

const resultToRows = (result: RootGraphQlQuery): ResultRow<Columns>[] => {
  const payments = result.payments?.map(payment => ({
    ...resultToRowsShared(payment),
    transportationProviderName:
      payment.shipments?.[0]?.transportationProvider?.name ?? ""
  }));

  return payments ?? [];
};

const resultColumns: ColumnProps<any>[] = [
  ...sharedResultColumns,
  {
    key: "transportationProviderName",
    dataIndex: "transportationProviderName",
    title: "Transportation provider name"
  }
];

const search: BaseSearch<Columns> = {
  name: "remittance",
  fields,
  defaultConditions,
  conditionsToQuery,
  resultToRows,
  resultColumns
};

export default search;
