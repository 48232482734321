import { ApolloProvider } from "@apollo/react-hooks";
import { message } from "antd";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Header from "components/Header/Header";
import Protected from "components/Protected/Protected";
import Report from "components/Report/Report";
import NotFoundRoute from "components/routes/NotFoundRoute/NotFoundRoute";
import ReportsRoute from "components/routes/ReportsRoute/ReportsRoute";
import SearchesRoute from "components/routes/SearchesRoute/SearchesRoute";
import client from "data/client";
import { StoreProvider } from "easy-peasy";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import usePermissions from "hooks/usePermissions";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AppInsightsService from "services/AppInsightsService";
import appStore from "state/appStore";
import {
  BUILD_ID,
  RELEASE_INFORMATIONAL_VERSION,
  RELEASE_VERSION
} from "../../config";
import "./App.less";

// Configure the global Message component.
message.config({
  top: 76,
  maxCount: 5
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Apollo = () => {
  const user = useStoreState(state => state.auth.user);
  const appInsightsService = useStoreState(state => state.appInsightsService);
  const signOut = useStoreActions(actions => actions.auth.signOut);

  return (
    <ApolloProvider
      client={client(appInsightsService as AppInsightsService, signOut, user)}
    >
      <div className="App">
        <Helmet>
          <meta
            name="generator"
            content={`${BUILD_ID} ${RELEASE_VERSION} ${RELEASE_INFORMATIONAL_VERSION}`}
          />
        </Helmet>
        <Protected>
          <Router />
        </Protected>
      </div>
    </ApolloProvider>
  );
};

const Router = () => {
  const { canAccessSearches } = usePermissions();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <div className="App-content">
        <Switch>
          <Route path="/" exact>
            <Redirect to={canAccessSearches ? "/searches" : "/reports"} />
          </Route>
          <Route path="/searches/:searchId?" component={SearchesRoute} />
          <Route path="/reports/new" component={Report} />
          <Route path="/reports/:reportId?" component={ReportsRoute} />
          <Route component={NotFoundRoute} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <ErrorBoundary>
      <StoreProvider store={appStore}>
        <Apollo />
      </StoreProvider>
    </ErrorBoundary>
  );
};

export default App;
