import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal, Tooltip } from "antd";
import { CREATE_REPORT } from "data/mutations";
import { GET_REPORTS } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootGraphQlMutation } from "types/graphql";

const ReportSaveNew = () => {
  const report = useStoreState(state => state.reports.report);
  const reportValid = useStoreState(state => state.reports.reportValid);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const [createReport, { loading, data, error }] = useMutation<
    RootGraphQlMutation
  >(CREATE_REPORT, {
    refetchQueries: [{ query: GET_REPORTS }]
  });
  const history = useHistory();

  useEffect(() => {
    const newId = data?.createReport?.reportId;
    if (newId) {
      setModalOpen(false);
      history.push(`/reports/${newId}`);
    }
  }, [data, history]);

  // Explain why the report can't be saved.
  const hasCustomer = report.customerOrCorporationKey;
  const hasFields = report.fields?.length;
  const tooltipText = `Before saving, ${
    !hasCustomer ? "pick a customer or corporation" : ""
  } ${!hasCustomer && !hasFields ? "and" : ""} ${
    !hasFields ? "add some fields" : ""
  }`;

  return (
    <>
      <Tooltip
        placement="top"
        title={(!hasCustomer || !hasFields) && tooltipText}
      >
        <Button
          type="link"
          icon={<SaveOutlined />}
          onClick={() => setModalOpen(true)}
          disabled={!reportValid}
        >
          Save Report
        </Button>
      </Tooltip>
      <Modal
        visible={modalOpen}
        onOk={() =>
          createReport({
            variables: {
              input: { ...report, name: reportName }
            }
          })
        }
        onCancel={() => setModalOpen(false)}
        maskClosable={false}
        okText={loading ? "Creating Report" : "Create Report"}
        okButtonProps={{ disabled: !reportName, loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <p>
          <strong>Save Report</strong>
        </p>
        <p>
          This will create a saved report that only you will have access to.
        </p>
        <Input
          placeholder="Name of new report"
          onChange={event => setReportName(event.target.value)}
          value={reportName}
          ref={input => input && input.focus()}
        />
        {error}
      </Modal>
    </>
  );
};

export default ReportSaveNew;
