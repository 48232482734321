import { DATE_STORED } from "data/formats";
import { Guid } from "guid-typescript";
import moment from "moment";
import {
  DateRangeType,
  Report,
  ReportCondition,
  ReportConditionOperand,
  ReportConditionOperator,
  ReportCurrencyType,
  ReportDateType,
  ReportField,
  ReportFieldDataType,
  ReportInput,
  ReportKeyType
} from "types/graphql";
import { Condition, Field, Operator } from "types/search";

const operatorsForFieldType = (type: ReportFieldDataType): Operator[] => {
  let operators: Operator[] = [];

  if (type === ReportFieldDataType.Text) {
    operators.push(
      {
        id: ReportConditionOperator.Contains,
        name: "Contains",
        type: "text",
        defaultValue: ""
      },
      {
        id: ReportConditionOperator.IsInList,
        name: "Is In List",
        type: "textArray",
        defaultValue: []
      },
      {
        id: ReportConditionOperator.IsNotInList,
        name: "Not In List",
        type: "textArray",
        defaultValue: []
      }
    );
  }

  if (
    type === ReportFieldDataType.Number ||
    type === ReportFieldDataType.Money
  ) {
    operators.push(
      {
        id: ReportConditionOperator.Equal,
        name: "Is Equal To",
        type: "number"
      },
      {
        id: ReportConditionOperator.IsGreaterThan,
        name: "Is Greater than",
        type: "number"
      },
      {
        id: ReportConditionOperator.IsGreaterThanOrEqual,
        name: "Is Greater Than Or Equal To",
        type: "number"
      },
      {
        id: ReportConditionOperator.IsLessThan,
        name: "Is Less Than",
        type: "number"
      },
      {
        id: ReportConditionOperator.IsLessThanOrEqual,
        name: "Is Less Than Or Equal To",
        type: "number"
      },
      {
        id: ReportConditionOperator.NotEqual,
        name: "Is Not Equal To",
        type: "number"
      },
      {
        id: ReportConditionOperator.IsInRange,
        name: "Is In Range",
        type: "numberRange"
      },
      {
        id: ReportConditionOperator.IsInList,
        name: "Is In List",
        type: "numberArray",
        defaultValue: []
      },
      {
        id: ReportConditionOperator.IsNotInList,
        name: "Not In List",
        type: "numberArray",
        defaultValue: []
      }
    );
  }

  if (type === ReportFieldDataType.Date) {
    operators.push(
      {
        id: ReportConditionOperator.Equal,
        name: "Is",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: ReportConditionOperator.IsGreaterThan,
        name: "Is After",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: ReportConditionOperator.IsGreaterThanOrEqual,
        name: "Is On Or After",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: ReportConditionOperator.IsLessThan,
        name: "Is Before",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: ReportConditionOperator.IsLessThanOrEqual,
        name: "Is On Or Before",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: ReportConditionOperator.NotEqual,
        name: "Is Not",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      // Temporarily disabled due to this bug:
      // https://dev.azure.com/nvisionglobal/Impact%20II/_workitems/edit/2971
      // {
      //   id: ReportConditionOperator.IsInRange,
      //   name: "Is In Range",
      //   type: "dateRange",
      //   defaultValue: [
      //     moment()
      //       .subtract("7", "days")
      //       .format(DATE_STORED),
      //     moment().format(DATE_STORED)
      //   ]
      // },
      {
        id: ReportConditionOperator.IsInList,
        name: "Is In List",
        type: "dateArray",
        defaultValue: []
      },
      {
        id: ReportConditionOperator.IsNotInList,
        name: "Not In List",
        type: "dateArray",
        defaultValue: []
      }
    );
  }

  return operators;
};

const convertFields = (reportFields: ReportField[]): Field[] => {
  return reportFields.map(field => ({
    id: field.value,
    name: field.label,
    operators: operatorsForFieldType(field.dataType)
  }));
};

const reportConditionsToConditions = (
  reportConditions?: ReportCondition[]
): Condition[] => {
  return (
    reportConditions?.map(reportCondition => {
      // ReportCondition values are always arrays of strings, whereas
      // Condition values have types determined by their operators.
      let value: any;
      switch (reportCondition.operator) {
        case ReportConditionOperator.Contains:
          value = reportCondition.value[0];
          break;
        case ReportConditionOperator.IsInList:
        case ReportConditionOperator.IsNotInList:
        case ReportConditionOperator.IsInRange:
          value = reportCondition.value;
          break;
        default:
          value = reportCondition.value[0];
          break;
      }

      return {
        id: reportCondition?.id || Guid.raw(),
        field: reportCondition?.fieldId,
        operand: reportCondition?.operand,
        operator: reportCondition?.operator,
        value
      };
    }) || []
  );
};

// Values for Conditions can be of any type, but ReportConditions must be string arrays.
const valueToReportConditionValue = (value: any): string[] => {
  // Even if no value has been set, we still need to include a value
  // as it's required by the ReportCondition type. So [""] is used as a
  // stand in for null and TextArrayPicker will ignore this value.
  if (value === null || value === undefined) {
    return [""];
  }

  if (Array.isArray(value)) {
    return value.map(item => item.toString());
  } else {
    return [value.toString()];
  }
};

const conditionsToReportConditions = (
  conditions?: Condition[]
): ReportCondition[] =>
  conditions?.map(condition => ({
    id: condition.id,
    fieldId: condition.field || "",
    operand: condition.operand || ReportConditionOperand.And,
    operator: condition.operator as ReportConditionOperator,
    value: valueToReportConditionValue(condition.value)
  })) || [];

const reportParseReportDateQuery = (
  rangeType?: DateRangeType,
  start?: string,
  end?: string
): [string, string] => {
  let dateStart: string;
  let dateEnd: string;

  switch (rangeType) {
    case DateRangeType.Monthly:
      dateStart = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(DATE_STORED);
      dateEnd = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(DATE_STORED);
      break;

    case DateRangeType.WeekMf:
      const weekMfMonday = moment()
        .subtract(1, "week")
        .startOf("isoWeek");
      dateStart = weekMfMonday.format(DATE_STORED);
      dateEnd = weekMfMonday.add(4, "day").format(DATE_STORED);
      break;

    case DateRangeType.WeekMs:
      dateStart = moment()
        .subtract(1, "week")
        .startOf("isoWeek")
        .format(DATE_STORED);
      dateEnd = moment()
        .subtract(1, "week")
        .endOf("isoWeek")
        .format(DATE_STORED);
      break;

    case DateRangeType.Specific:
    default:
      const defaultStartDate = moment()
        .subtract(1, "week")
        .format(DATE_STORED);
      dateStart = start || defaultStartDate;
      dateEnd = end || moment().format(DATE_STORED);
      break;
  }

  return [dateStart, dateEnd];
};

const validReport = (report?: Partial<Report>) => {
  if (
    report?.keyType &&
    report.customerOrCorporationKey &&
    report.dateType &&
    report.dateRangeType &&
    report.fields &&
    report.fields.length !== 0 &&
    report.currencyType
  ) {
    return true;
  }

  return false;
};

const reportToReportInput = (report?: Partial<Report>): ReportInput | null => {
  if (!report || !validReport(report)) {
    return null;
  }

  // Some fields are required for ReportInput that are not required
  // for a report. These are here for type safety and shouldn't usually
  // be used, as each field should be required in the validation above.
  const reportInput: ReportInput = {
    name: report.name,
    reportId: report.reportId,
    customerOrCorporationKey: report.customerOrCorporationKey || 0,
    keyType: report.keyType || ReportKeyType.Corporation,
    dateType: report.dateType || ReportDateType.EntryDate,
    fields: report.fields || [],
    currencyType: report.currencyType || ReportCurrencyType.BilledCurrency,
    currencyOverride: report.currencyOverride,
    conditions: report.conditions?.map(c => ({
      id: c.id,
      fieldId: c.fieldId,
      operand: c.operand,
      operator: c.operator,
      value: c.value
    })),
    sortByFields: report.sortByFields,
    groupByFields: report.groupByFields,
    startDate: report.startDate,
    endDate: report.endDate,
    dateRangeType: report.dateRangeType,
    schedule: {
      id: 0, // Required by the type, but ignored when creating/updating.
      isActive: true, // Always set to true for now (potential feature for later).
      cronSchedule: report.schedule?.cronSchedule,
      recipients: report.schedule?.recipients || [],
      subject: report.schedule?.subject,
      message: report.schedule?.message
    },
    sharedWith: report.sharedWith?.map(u => ({ id: u.id }))
  };

  return reportInput;
};

const defaultReport: Partial<Report> = {
  dateType: ReportDateType.ShipDate,
  dateRangeType: DateRangeType.WeekMf,
  currencyType: ReportCurrencyType.CustomerCurrency
};

export {
  convertFields,
  reportConditionsToConditions,
  conditionsToReportConditions,
  reportParseReportDateQuery,
  reportToReportInput,
  validReport,
  defaultReport
};
