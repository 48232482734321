import { QuestionCircleFilled } from "@ant-design/icons";
import { Popover } from "antd";
import React, { ReactNode, ReactText } from "react";
import "./HelpPopover.less";

interface Props {
  text: ReactNode | ReactText;
}

const HelpPopover: React.FC<Props> = ({ text }) => {
  return (
    <Popover className="HelpPopover" content={text}>
      <QuestionCircleFilled />
    </Popover>
  );
};

export default HelpPopover;
