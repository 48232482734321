import Notifications from "components/Notifications/Notifications";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import usePermissions from "hooks/usePermissions";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.less";

interface Props {}

const Header: React.FC<Props> = () => {
  const user = useStoreState(state => state.auth.user);
  const signOut = useStoreActions(actions => actions.auth.signOut);
  const strings = useLocalizedStrings();
  const { canAccessSearches, canAccessReports } = usePermissions();

  return (
    <header className="Header">
      <div className="Header-contentWrapper">
        <div className="Header-leftWrapper">
          <Link to="/">
            <h1 className="Header-title">{strings.appTitle}</h1>
          </Link>
          {canAccessSearches && (
            <NavLink to="/searches" className="Header-link">
              {strings.searches}
            </NavLink>
          )}
          {canAccessReports && (
            <NavLink to="/reports" className="Header-link">
              {strings.reports}
            </NavLink>
          )}
        </div>
        <div className="Header-rightWrapper">
          <Notifications />
          {user && (
            <div className="Header-user">
              <span className="Header-user-name">{user?.displayName}</span>
              <span
                className="Header-user-signOut"
                onClick={() => user && signOut(user)}
              >
                {strings.signOut}
              </span>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
