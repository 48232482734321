import { createStore, persist } from "easy-peasy";
import appModel from "./appModel";

const appStore = createStore(
  persist(appModel, {
    blacklist: ["appInsightsService", "auth"]
  })
);

export default appStore;
