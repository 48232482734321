import {
  CheckOutlined,
  ExportOutlined,
  FilterOutlined
} from "@ant-design/icons";
import { useApolloClient } from "@apollo/react-hooks";
import { Button, Dropdown, Empty, Menu, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { GET_EXPORT } from "data/queries";
import React, { useContext, useEffect, useState } from "react";
import SavedSearchContext from "state/SavedSearchContext";
import { ResultRow } from "types/search";
import "./Results.less";

interface Props {
  rows: ResultRow<string>[];
  loading?: boolean;
}

const Results: React.FC<Props> = ({ rows, loading = false }) => {
  const client = useApolloClient();
  const [exporting, setExporting] = useState(false);
  const { baseSearch, conditions } = useContext(SavedSearchContext);

  const [selectedColumnKeys, setSelectedColumnKeys] = useState(
    baseSearch.resultColumns.map(column => column.key as string)
  );
  useEffect(() => {
    setSelectedColumnKeys(
      baseSearch.resultColumns.map(column => column.key as string)
    );
  }, [baseSearch]);

  const handleExportClicked = async () => {
    setExporting(true);

    const { data } = await client.query({
      query: GET_EXPORT,
      variables: { query: baseSearch.conditionsToQuery(conditions, true) }
    });

    setExporting(false);

    const url = data.exports.url;
    if (url) {
      window.location.href = url;
    }
  };

  const selectedColumns = baseSearch.resultColumns.filter(column =>
    selectedColumnKeys.includes(column.key as string)
  );

  const menu = (
    <Menu
      className="Results-menu"
      multiple
      selectable
      selectedKeys={selectedColumnKeys}
      onSelect={item =>
        setSelectedColumnKeys([...selectedColumnKeys, item.key])
      }
      onDeselect={item =>
        setSelectedColumnKeys(
          selectedColumnKeys.filter(key => key !== item.key)
        )
      }
    >
      {baseSearch.resultColumns.map(column => (
        <Menu.Item key={column.key}>
          <CheckOutlined />
          {column.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="Results">
      <div className="Results-title">
        <Title level={4}>Results</Title>
      </div>
      <div className="Results-actions">
        <Dropdown trigger={["click"]} overlay={menu}>
          <Button icon={<FilterOutlined />}>Columns</Button>
        </Dropdown>
        <Button
          icon={<ExportOutlined />}
          onClick={handleExportClicked}
          loading={exporting}
          disabled={rows.length === 0 || exporting}
        >
          {exporting ? "Exporting..." : "Export"}
        </Button>
      </div>
      <div className="Results-table">
        <Table
          rowKey={item => item.id || ""}
          pagination={{
            size: "small",
            showTotal: total =>
              total < 100
                ? `${rows.length} results found`
                : "Showing the first 100 results"
          }}
          style={{ minWidth: 800 }}
          size="middle"
          dataSource={rows}
          columns={selectedColumns}
          loading={loading}
          tableLayout="fixed"
          locale={{
            emptyText: (
              <Empty
                description="No results found, try modifying your search."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          }}
        />
      </div>
    </div>
  );
};

export default Results;
