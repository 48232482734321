import { IMPACT_AUTH_PATH } from "config";
import { Action, action, Thunk, thunk } from "easy-peasy";
import { uniq } from "lodash";

export type AuthUser = {
  username?: string;
  id?: string;
  displayName?: string;
  token?: string;
  hasPaidDateAccess?: boolean;
};

export interface AuthModel {
  user?: AuthUser;
  setUser: Action<AuthModel, AuthUser>;
  signingIn: boolean;
  setSigningIn: Action<AuthModel, boolean>;
  error: Error | null;
  setError: Action<AuthModel, Error | null>;
  groups?: string[];
  setGroups: Action<AuthModel, string[]>;
  signIn: Thunk<AuthModel, { username: string; password: string, otp: string }>;
  signOut: Thunk<AuthModel, AuthUser>;
}

export const AUTH_PATH = `${IMPACT_AUTH_PATH}/authenticate`;
export const LOCAL_STORAGE_KEY = "user";
export const SIGN_OUT_PATH = `${IMPACT_AUTH_PATH}/signout`;

export const getStoredUser = () => {
  const storedUser = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (storedUser) {
    const user: AuthUser = JSON.parse(storedUser);
    return user;
  } else {
    return {};
  }
};

const authModel: AuthModel = {
  user: getStoredUser(),
  setUser: action((state, payload) => {
    state.user = payload;

    // Sync any changes to local storage.
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(payload));
  }),
  signingIn: false,
  setSigningIn: action((state, payload) => {
    state.signingIn = payload;
  }),
  error: null,
  setError: action((state, payload) => {
    state.error = payload;
  }),
  groups: [],
  setGroups: action((state, payload) => {
    state.groups = payload;
  }),
  signIn: thunk(async (actions, { username, password, otp }) => {
    actions.setSigningIn(true);

    // Attempt sign in.
    let credentials = new FormData();
    credentials.append("Username", username);
    credentials.append("Password", password);
    credentials.append("OTP", otp);
    const responseString = await fetch(AUTH_PATH, {
      method: "post",
      body: credentials
    });
    const response = await responseString.json();

    // Exit if something went wrong.
    if (response.message) {
      actions.setSigningIn(false);
      return actions.setError(new Error(response.message));
    }

    // Clear any previous errors.
    actions.setError(null);

    // Set the user.
    const authenticatedUser: AuthUser = {
      username: response.email,
      id: response.id,
      displayName: response.displayName,
      token: response.token,
      hasPaidDateAccess: response.hasPaidDateAccess
    };
    actions.setUser(authenticatedUser);

    // Set the groups.
    const groups: { [key: string]: string }[] = response.groups;
    const uniqueGroups = uniq(groups.map(g => g.groupName));
    actions.setGroups(uniqueGroups);

    actions.setSigningIn(false);
  }),
  signOut: thunk((actions, { username, token }) => {
    fetch(SIGN_OUT_PATH, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    // Keep their username for next time.
    actions.setUser({
      username: username || ""
    });
  })
};

export default authModel;
