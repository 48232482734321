// NOTE: This is a proof of concept for using localized strings throughout
//       the application. It's currently only in use for the Header component
//       and there is no functionality to change the selected language.

type Locales = "en-us" | "en-ca";
type StringKeys = "appTitle" | "searches" | "reports" | "signOut";
type LocalizedStrings = { [key in StringKeys]: string };
type LocalizedStringsGroup = { [key in Locales]: LocalizedStrings };

const useLocalizedStrings = (): LocalizedStrings => {
  const selectedLocale: Locales = "en-us";

  const baseStrings: LocalizedStrings = {
    appTitle: "Impact Adhoc Reporting",
    searches: "Searches",
    reports: "Reports",
    signOut: "Sign Out"
  };

  const localizedStrings: LocalizedStringsGroup = {
    "en-us": baseStrings,
    "en-ca": baseStrings
  };

  return localizedStrings[selectedLocale] as LocalizedStrings;
};

export default useLocalizedStrings;
