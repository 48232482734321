import { useQuery } from "@apollo/react-hooks";
import NewSearch from "components/NewSearch/NewSearch";
import Page from "components/Page/Page";
import SavedList from "components/SavedList/SavedList";
import { GET_SEARCHES } from "data/queries";
import React from "react";
import { RootGraphQlQuery } from "types/graphql";

interface Props {}

const SavedSearches: React.FC<Props> = () => {
  const { data } = useQuery<RootGraphQlQuery>(GET_SEARCHES);

  const searches = data?.searches;

  return (
    <Page>
      <SavedList
        title="Searches"
        actions={<NewSearch />}
        items={searches?.map(search => ({
          title: search.name,
          author: search.createdBy?.displayName || "Unknown user",
          authorId: search.createdBy?.id.toString() || "",
          link: `/searches/${search.id}`,
          date: search.dateModified
        }))}
      />
    </Page>
  );
};

export default SavedSearches;
