import { useLazyQuery } from "@apollo/react-hooks";
import { Select } from "antd";
import { GET_CUSTOMER, GET_CUSTOMERS } from "data/queries";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import React, { useEffect } from "react";
import {
  ApplicationContext,
  ReportCurrencyType,
  ReportKeyType,
  RootGraphQlQuery
} from "types/graphql";

const DEFAULT_CURRENCY = "USD";
const BILLED_CURRENCY_PREFIX = "billcurr";
const CUSTOMER_CURRENCY_PREFIX = "custcurr";

const ReportCurrencyPicker = () => {
  const report = useStoreState(state => state.reports.report);
  const setReport = useStoreActions(actions => actions.reports.setReport);

  const [
    getCustomer,
    { error: customerError, data: customerData }
  ] = useLazyQuery<RootGraphQlQuery>(GET_CUSTOMER, {
    variables: {
      context: ApplicationContext.Reporting,
      customerKey: report.customerOrCorporationKey
    }
  });

  const [
    getCustomers,
    { error: customersError, data: customersData }
  ] = useLazyQuery<RootGraphQlQuery>(GET_CUSTOMERS, {
    variables: { context: ApplicationContext.Reporting }
  });

  useEffect(() => {
    if (report.keyType === ReportKeyType.Customer) {
      report.customerOrCorporationKey && getCustomer();
    } else {
      report.customerOrCorporationKey && getCustomers();
    }
  }, [
    report.keyType,
    report.customerOrCorporationKey,
    getCustomer,
    getCustomers
  ]);

  if (customerError || customersError) {
    return <div>Something went wrong.</div>;
  }

  const handleSelectChange = (value: string) => {
    const parts = value.split("_");
    let currencyType: ReportCurrencyType = ReportCurrencyType.BilledCurrency;
    let currencyOverride: string = "";

    if (parts[0] !== BILLED_CURRENCY_PREFIX) {
      currencyType = ReportCurrencyType.CustomerCurrency;
      if (parts[1] !== null) {
        currencyOverride = parts[1];
      }
    }

    setReport({ ...report, currencyType, currencyOverride });
  };

  const currencies =
    report.keyType === ReportKeyType.Customer
      ? customerData?.customers?.map(c => c.baseCurrency ?? DEFAULT_CURRENCY)
      : customersData?.customers
          ?.filter(
            c =>
              c.corporation?.key === report.customerOrCorporationKey &&
              c.corporation?.baseCurrency
          )
          ?.map(c => c.baseCurrency)
          .concat(DEFAULT_CURRENCY)
          .filter((v, i, a) => a.indexOf(v) === i);

  const getValue = () => {
    if (!report.currencyType || !currencies || currencies.length === 0) {
      return "Customer's currency";
    }

    const currencyValue =
      report.currencyOverride !== "" &&
      report.currencyOverride !== null &&
      currencies.indexOf(report.currencyOverride!) > 0
        ? report.currencyOverride
        : currencies[0];

    return report.currencyType === ReportCurrencyType.BilledCurrency
      ? `${BILLED_CURRENCY_PREFIX}`
      : `${CUSTOMER_CURRENCY_PREFIX}_${currencyValue}`;
  };

  return (
    <Select
      value={getValue()}
      placeholder="Select currency..."
      onChange={handleSelectChange}
    >
      {currencies &&
        currencies.map(currency => (
          <Select.Option
            key={currency}
            value={`${CUSTOMER_CURRENCY_PREFIX}_${currency}`}
          >
            Customer's currency ({currency})
          </Select.Option>
        ))}
      <Select.Option
        key={BILLED_CURRENCY_PREFIX}
        value={`${BILLED_CURRENCY_PREFIX}`}
      >
        Billed currency
      </Select.Option>
    </Select>
  );
};

export default ReportCurrencyPicker;
