import closeout from "./closeout";
import remittance from "./remittance";

const baseSearches = [
  {
    id: "remittance",
    baseSearch: remittance
  },
  {
    id: "closeout",
    baseSearch: closeout
  }
];

export default baseSearches;
