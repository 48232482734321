import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Popover, Select, Spin } from "antd";
import CustomerPicker from "components/CustomerPicker/CustomerPicker";
import { GET_CUSTOMER_BRANCHES } from "data/queries";
import React, { useEffect, useState } from "react";
import { Branch, RootGraphQlQuery } from "types/graphql";
import "./BranchPicker.less";

interface PopoverContentProps {
  onChange: (branch: Branch) => void;
}

const PopoverContent: React.FC<PopoverContentProps> = ({ onChange }) => {
  const [customerKey, setCustomerKey] = useState<number>();
  const [getBranches, { loading, error, data }] = useLazyQuery<
    RootGraphQlQuery
  >(GET_CUSTOMER_BRANCHES, {
    variables: { customerKey }
  });

  // Query for branches whenever the customer is set.
  useEffect(() => {
    customerKey && getBranches();
  }, [customerKey, getBranches]);

  if (error) {
    return <div>Something went wrong.</div>;
  }

  const divisions = data?.customers?.[0].divisions;

  // Extract the branches from each division.
  let branches: Branch[] = [];
  divisions?.forEach(division => {
    division?.regions?.forEach(region => {
      region?.branches?.forEach(branch => branch && branches.push(branch));
    });
  });

  return (
    <div className="BranchPicker-content">
      <CustomerPicker
        customerOrCorporationKey={customerKey}
        onChange={(type, key) => setCustomerKey(key)}
      />
      {branches.length > 0 && (
        <Select
          showSearch
          placeholder="Select a branch"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={(inputValue, option) => {
            const text = (option?.props.children as string).toUpperCase();
            return text.includes(inputValue.toUpperCase());
          }}
          onChange={branchKey => {
            const selectedBranch = branches?.find(
              branch => branch?.key === Number(branchKey)
            );
            selectedBranch && onChange(selectedBranch);
          }}
          style={{ width: "100%" }}
        >
          {branches.map(branch => (
            <Select.Option key={branch?.key} value={branch?.key}>
              {branch?.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

interface Props {
  branch?: Branch;
  onChange: (branch: Branch) => void;
}

const BranchPicker: React.FC<Props> = ({ branch, onChange }) => {
  return (
    <div className="BranchPicker">
      {!branch && (
        <Popover
          content={
            <PopoverContent
              onChange={branch => {
                onChange(branch);
              }}
            />
          }
          title="Select a branch"
          trigger="click"
          placement="bottom"
        >
          <Button>Select branch...</Button>
        </Popover>
      )}
      {branch && <div className="BranchPicker-value">{branch.name}</div>}
    </div>
  );
};

export default BranchPicker;
