import { useQuery } from "@apollo/react-hooks";
import { Button, Input, Select, Tooltip } from "antd";
import { GET_USERS } from "data/queries";
import React, { useState } from "react";
import { ReportKeyType, RootGraphQlQuery, User } from "types/graphql";
import "./UserPicker.less";

interface Props {
  onChange: (users: User[]) => void;
  selectedEmails?: string[];
  customerOrCorporationKey?: number;
  customerOrCorporationKeyType?: ReportKeyType;
  onlyAllowKnownEmails?: boolean;
}

const UserPicker = ({
  onChange,
  selectedEmails = [],
  customerOrCorporationKey,
  customerOrCorporationKeyType,
  onlyAllowKnownEmails = false
}: Props) => {
  const [newEmail, setNewEmail] = useState("");
  const { loading, data } = useQuery<RootGraphQlQuery>(GET_USERS, {
    variables: {
      key: customerOrCorporationKey,
      keyType: customerOrCorporationKeyType
    },
    skip: !customerOrCorporationKey || !customerOrCorporationKeyType
  });

  const users = data?.users;

  const filteredUsers = data?.users?.filter(
    user =>
      !user.email.toLowerCase().includes("nvisionglobal.com") &&
      !user.email.toLowerCase().includes("tranistics.com") &&
      !user.email.toLowerCase().includes("facetdev.com")
  );

  const newEmailValid =
    /\S+@\S+\.\S+/.test(newEmail) &&
    (!onlyAllowKnownEmails || users?.find(user => user.email === newEmail));

  const handleSelectChange = (selectedEmails: string[]) => {
    let selectedUsers: User[] = [];

    // For each of the emails, add a User object to the return array. This
    // will either be the matching user from the query, or a freshly-created
    // User with the custom email (happens when onlyAllowKnownEmails
    // flag is false).
    selectedEmails.forEach(email => {
      const matchingUser = users?.find(user => user.email === email);
      if (matchingUser) {
        selectedUsers.push(matchingUser);
      } else {
        selectedUsers.push({
          id: Math.floor(Math.random() * Math.floor(5000)),
          dateCreated: Date.now(),
          dateModified: Date.now(),
          displayName: email,
          email
        });
      }
    });

    onChange(selectedUsers);
  };

  const renderDropdown = (menu: React.ReactElement) => (
    <>
      {menu}
      <div className="UserPicker-newEmail">
        <Input
          value={newEmail}
          onChange={event => setNewEmail(event.target.value)}
          placeholder="Add another email"
          onKeyDown={event => {
            // The select component listens to all keydown events, so we need to
            // prevent it from responding to users pressing the backspace key.
            event.stopPropagation();
          }}
        />
        <Tooltip
          title={!newEmailValid && "Please add an authorized user's email"}
        >
          <Button
            type="primary"
            disabled={!newEmailValid}
            htmlType="submit"
            onClick={() => {
              handleSelectChange([...selectedEmails, newEmail]);
              setNewEmail("");
            }}
          >
            Add
          </Button>
        </Tooltip>
      </div>
    </>
  );

  return (
    <Select
      className="UserPicker"
      mode="multiple"
      filterOption={(inputValue, option) => {
        const text = (option?.children
          ? option?.children.join("")
          : option?.value
        ).toUpperCase();
        return text.includes(inputValue.toUpperCase());
      }}
      loading={loading}
      optionLabelProp="label"
      placeholder="Select Users"
      onChange={handleSelectChange}
      value={selectedEmails}
      dropdownRender={renderDropdown}
    >
      {filteredUsers
        ?.sort((a, b) => (a.email > b.email ? 1 : -1))
        .map(user => (
          <Select.Option
            key={user.email}
            value={user.email}
            label={
              <Tooltip title={user.email}>
                <span>{user.displayName}</span>
              </Tooltip>
            }
          >
            {user.displayName} ({user.email})
          </Select.Option>
        ))}
    </Select>
  );
};

export default UserPicker;
