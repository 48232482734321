import { CopyOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal } from "antd";
import { CREATE_REPORT } from "data/mutations";
import { GET_REPORTS } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RootGraphQlMutation } from "types/graphql";
import { reportToReportInput } from "../Report.helpers";

const ReportDuplicate = () => {
  const report = useStoreState(state => state.reports.report);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const [createReport, { loading, data, error }] = useMutation<
    RootGraphQlMutation
  >(CREATE_REPORT, {
    refetchQueries: [{ query: GET_REPORTS }]
  });
  const history = useHistory();

  useEffect(() => {
    const newId = data?.createReport?.reportId;
    if (newId) {
      setModalOpen(false);
      history.push(`/reports/${newId}`);
    }
  }, [data, history]);

  return (
    <>
      <Button
        type="link"
        icon={<CopyOutlined />}
        onClick={() => setModalOpen(true)}
      >
        Duplicate
      </Button>
      <Modal
        visible={modalOpen}
        onOk={() =>
          createReport({
            variables: {
              input: {
                ...reportToReportInput(report),
                name: reportName
              }
            }
          })
        }
        onCancel={() => setModalOpen(false)}
        maskClosable={false}
        okText={loading ? "Creating Report" : "Create Report"}
        okButtonProps={{ disabled: !reportName, loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <p>
          <strong>Duplicate Report</strong>
        </p>
        <p>
          This will create a copy of the current report. Only you will have
          access to the new report.
        </p>
        <Input
          placeholder="Name of new report"
          onChange={event => setReportName(event.target.value)}
          value={reportName}
          ref={input => input && input.focus()}
        />
        {error}
      </Modal>
    </>
  );
};

export default ReportDuplicate;
