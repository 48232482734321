import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import UserPicker from "components/UserPicker/UserPicker";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { ReportFieldSpecialtyType, User } from "types/graphql";

const ReportShare = () => {
  const report = useStoreState(state => state.reports.report);
  const isAdHoc = useStoreState(state => state.reports.reportAdHoc);
  const fields = useStoreState(state => state.reports.fields);
  const setReport = useStoreActions(actions => actions.reports.setReport);
  const [sharedWith, setSharedWith] = useState<User[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setSharedWith(report.sharedWith || []);
  }, [report]);

  // Reports with special fields cannot be shared.
  const specialFields = fields
    .filter(f => report.fields?.includes(f.value))
    .filter(f => f.specialtyType === ReportFieldSpecialtyType.Special);

  const hasSpecialFields = specialFields.length !== 0;

  const tooltip = hasSpecialFields
    ? `To share, first remove the fields that are not available to everyone: ${specialFields
        .map(f => f.label)
        .join(",")}`
    : "Only saved reports can be shared.";

  return (
    <>
      <Tooltip title={tooltip}>
        <Button
          type="link"
          icon={<ShareAltOutlined />}
          disabled={hasSpecialFields || isAdHoc}
          onClick={() => setModalOpen(true)}
        >
          Share
        </Button>
      </Tooltip>
      <Modal
        visible={modalOpen}
        maskClosable={true}
        onOk={() => {
          setReport({ ...report, sharedWith });
          setModalOpen(false);
        }}
        onCancel={() => setModalOpen(false)}
      >
        <p>
          <strong>Share Report</strong>
        </p>
        <p>
          Allow others to access this report. Only you can make changes to it.
        </p>
        <UserPicker
          customerOrCorporationKey={report?.customerOrCorporationKey}
          customerOrCorporationKeyType={report?.keyType}
          onChange={selectedUsers => setSharedWith(selectedUsers)}
          selectedEmails={sharedWith.map(u => u.email)}
          onlyAllowKnownEmails={true}
        />
      </Modal>
    </>
  );
};

export default ReportShare;
