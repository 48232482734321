import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Popover, Select, Spin } from "antd";
import CustomerPicker from "components/CustomerPicker/CustomerPicker";
import { GET_CUSTOMER_REGIONS } from "data/queries";
import React, { useEffect, useState } from "react";
import { Region, RootGraphQlQuery } from "types/graphql";
import "./RegionPicker.less";

interface PopoverContentProps {
  onChange: (region: Region) => void;
}

const PopoverContent: React.FC<PopoverContentProps> = ({ onChange }) => {
  const [customerKey, setCustomerKey] = useState<number>();
  const [getRegions, { loading, error, data }] = useLazyQuery<RootGraphQlQuery>(
    GET_CUSTOMER_REGIONS,
    {
      variables: { customerKey }
    }
  );

  // Query for regions whenever the customer is set.
  useEffect(() => {
    customerKey && getRegions();
  }, [customerKey, getRegions]);

  if (error) {
    return <div>Something went wrong.</div>;
  }

  const divisions = data?.customers?.[0].divisions;

  // Extract the regions from each division.
  let regions: Region[] = [];
  divisions?.forEach(division => {
    division?.regions?.forEach(region => region && regions.push(region));
  });

  return (
    <div className="RegionPicker-content">
      <CustomerPicker
        customerOrCorporationKey={customerKey}
        onChange={(type, key) => setCustomerKey(key)}
      />
      {regions.length > 0 && (
        <Select
          showSearch
          placeholder="Select a region"
          notFoundContent={loading ? <Spin size="small" /> : null}
          filterOption={(inputValue, option) => {
            const text = (option?.props.children as string).toUpperCase();
            return text.includes(inputValue.toUpperCase());
          }}
          onChange={regionKey => {
            const selectedRegion = regions?.find(
              region => region?.key === Number(regionKey)
            );
            selectedRegion && onChange(selectedRegion);
          }}
          style={{ width: "100%" }}
        >
          {regions.map(region => (
            <Select.Option key={region.key} value={region.key}>
              {region.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

interface Props {
  region?: Region;
  onChange: (region: Region) => void;
}

const RegionPicker: React.FC<Props> = ({ region, onChange }) => {
  return (
    <div className="RegionPicker">
      {!region && (
        <Popover
          content={
            <PopoverContent
              onChange={region => {
                onChange(region);
              }}
            />
          }
          title="Select a region"
          trigger="click"
          placement="bottom"
        >
          <Button>Select region...</Button>
        </Popover>
      )}
      {region && <div className="RegionPicker-value">{region.name}</div>}
    </div>
  );
};

export default RegionPicker;
