import { Checkbox, Form, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const repeatOptionForSchedule = (schedule: string): RepeatOption => {
  const [, , monthDay, , weekDay] = schedule.split(" ");

  if (monthDay !== "*") {
    return "monthly";
  } else if (weekDay !== "*") {
    return "weekly";
  } else {
    return "daily";
  }
};

type RepeatOption = "daily" | "weekly" | "monthly";

interface Props {
  onChange: (schedule: string) => void;
  schedule: string;
}

const Scheduler: React.FC<Props> = ({ schedule, onChange }) => {
  const [repeats, setRepeats] = useState<RepeatOption>();
  const [time, setTime] = useState<moment.Moment | null>();
  const [weekdays, setWeekdays] = useState<number[]>();
  const [monthday, setMonthday] = useState<number | null>();

  // Whenever a schedule is sent in, update the selections to match.
  useEffect(() => {
    if (schedule) {
      setRepeats(repeatOptionForSchedule(schedule));

      const [minute, hour, monthDay, , weekDay] = schedule.split(" ");

      if (monthDay !== "*") {
        setMonthday(parseInt(monthDay));
      } else if (weekDay !== "*") {
        setWeekdays(weekDay.split(",").map(day => parseInt(day)));
      } else {
      }

      if (hour !== "*" && minute !== "*") {
        setTime(
          moment()
            .hour(parseInt(hour))
            .minute(parseInt(minute))
        );
      }
    }
  }, [schedule]);

  // Update the schedule (sent as callback to parent) when the schedule parts change.
  useEffect(() => {
    // On the first render, these may still be undefined as the effect above
    // has not yet run to update their values based on the schedule.
    if (
      time !== undefined ||
      weekdays !== undefined ||
      monthday !== undefined
    ) {
      const timePart = (time && `${time.minute()} ${time.hour()}`) || "* *";
      const monthdayPart = monthday || "*";
      const weekdaysPart = (!monthday && weekdays && weekdays.join(",")) || "*";

      const newSchedule = `${timePart} ${monthdayPart} * ${weekdaysPart}`;
      onChange(newSchedule);
    }
  }, [time, weekdays, monthday, onChange]);

  // Set defaults for each of the repeat options.
  const handleRepeatsChange = (repeats: RepeatOption) => {
    setRepeats(repeats);

    switch (repeats) {
      case "daily":
        setWeekdays([]);
        setMonthday(null);
        break;
      case "weekly":
        setWeekdays([5]);
        setMonthday(null);
        break;
      case "monthly":
        setWeekdays([]);
        setMonthday(1);
        break;
    }
  };

  return (
    <div>
      <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
        <Form.Item label="Repeat">
          <Select
            value={repeats}
            onChange={handleRepeatsChange}
            data-testid="repeats"
          >
            <Select.Option value="daily">Every day</Select.Option>
            <Select.Option value="weekly">Every week</Select.Option>
            <Select.Option value="monthly">Every month</Select.Option>
          </Select>
        </Form.Item>
        {repeats === "weekly" && (
          <Form.Item label="On days">
            <Checkbox.Group
              value={weekdays}
              onChange={checked => setWeekdays(checked as number[])}
            >
              {dayNames.map((day, index) => (
                <div key={day}>
                  <Checkbox value={index}>{day}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
        {repeats === "monthly" && (
          <Form.Item label="On day">
            <Select
              value={monthday || undefined}
              onChange={setMonthday}
              placeholder="Select a day"
            >
              {[...Array(31)].map((day, index) => (
                <Select.Option key={index + 1} value={index + 1}>
                  {index + 1}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="At time">
          <TimePicker
            format="h:mm A"
            minuteStep={15}
            use12Hours
            value={time}
            onChange={setTime}
            allowClear={false}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Scheduler;
