import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import baseSearches from "data/baseSearches";
import { CREATE_SEARCH } from "data/mutations";
import { GET_SEARCHES } from "data/queries";
import { ExecutionResult } from "graphql";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseSearch, Condition } from "types/search";

type CreateSearchHook = (search: {
  name: string;
  baseSearch: string;
  conditions?: Condition[];
}) => [() => void, boolean, string | undefined];

const useCreateSearch: CreateSearchHook = search => {
  const [baseSearch, setBaseSearch] = useState<BaseSearch<any>>();
  const [createSearch, { loading, error }] = useMutation<{
    createSearch: { id: number };
  }>(CREATE_SEARCH, {
    variables: {
      input: {
        name: search.name,
        baseSearch: search.baseSearch,
        query: search.conditions
          ? baseSearch?.conditionsToQuery(search.conditions)
          : baseSearch?.conditionsToQuery(baseSearch?.defaultConditions),
        conditions:
          JSON.stringify(search?.conditions) ||
          JSON.stringify(baseSearch?.defaultConditions)
      }
    },
    refetchQueries: [{ query: GET_SEARCHES }],
    onError: () => message.error("Unable to create search.")
  });
  const history = useHistory();

  useEffect(() => {
    setBaseSearch(
      baseSearches.find(baseSearch => baseSearch.id === search.baseSearch)
        ?.baseSearch
    );
  }, [search, baseSearch]);

  const createSearchFunction = async () => {
    const result: ExecutionResult<{
      createSearch: { id: number };
    }> = await createSearch();
    const newId = result.data?.createSearch.id;
    history.push(`/searches/${newId}`);
  };

  const errorMessage = error?.message;

  return [createSearchFunction, loading, errorMessage];
};

export default useCreateSearch;
