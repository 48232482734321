import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, message } from "antd";
import { UPDATE_REPORT } from "data/mutations";
import { GET_REPORT, GET_REPORTS } from "data/queries";
import { useStoreState } from "hooks/storeHooks";
import React from "react";
import { RootGraphQlMutation } from "types/graphql";
import { reportToReportInput } from "../Report.helpers";

const ReportSave = () => {
  const report = useStoreState(state => state.reports.report);
  const reportValid = useStoreState(state => state.reports.reportValid);
  const reportModified = useStoreState(state => state.reports.reportModified);

  const [updateReport, { loading }] = useMutation<RootGraphQlMutation>(
    UPDATE_REPORT,
    {
      refetchQueries: [
        { query: GET_REPORT, variables: { id: report?.reportId } },
        { query: GET_REPORTS }
      ],
      onCompleted: () => message.success("Changes saved."),
      onError: () => message.error("Unable to save changes.")
    }
  );

  return (
    <Button
      type="link"
      icon={<SaveOutlined />}
      onClick={() =>
        updateReport({
          variables: { input: reportToReportInput(report) }
        })
      }
      disabled={loading || !reportValid || !reportModified}
    >
      {loading ? "Saving..." : "Save Changes"}
    </Button>
  );
};

export default ReportSave;
