import Icon, { EyeInvisibleOutlined } from "@ant-design/icons";
import { Tooltip, Transfer } from "antd";
import { TransferItem } from "antd/lib/transfer";
import usePrevious from "hooks/usePrevious";
import { intersection, union } from "lodash";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { ReportField, ReportFieldSpecialtyType } from "types/graphql";
// Icons made by Freepik from www.flaticon.com
import { ReactComponent as packageIcon } from "./package.svg";
import "./ReportTransfer.less";
import { ReactComponent as truckIcon } from "./truck.svg";

const renderTransferItem = (item: TransferItem) => (
  <span className="ReportTransfer-item">
    {item.title}
    <div>
      {item.specialtyType === ReportFieldSpecialtyType.Special && (
        <Tooltip title="This field isn't available to everyone.">
          <EyeInvisibleOutlined className="ReportTransfer-item-special" />
        </Tooltip>
      )}
      {item.specialtyType === ReportFieldSpecialtyType.Udf && (
        <Tooltip title="Freight Bill level UDF.">
          <Icon
            className="ReportTransfer-icon ReportTransfer-item-special"
            component={truckIcon}
          />
        </Tooltip>
      )}
      {item.specialtyType === ReportFieldSpecialtyType.PackageUdf && (
        <Tooltip title="Package level UDF.">
          <Icon
            className="ReportTransfer-icon ReportTransfer-item-special"
            component={packageIcon}
          />
        </Tooltip>
      )}
      <span className="ReportTransfer-item-type">{item.dataType}</span>
    </div>
  </span>
);

const reportFieldsToTransferItems = (
  reportFields?: ReportField[]
): TransferItem[] => {
  if (!reportFields) {
    return [];
  }

  return reportFields.map(field => {
    return {
      key: field.value,
      title: field.label,
      dataType: field.dataType,
      specialtyType: field.specialtyType
    };
  });
};

interface Props {
  onChange: (includedFieldValues: string[]) => void;
  availableFields?: ReportField[];
  includedFieldValues?: string[];
}

const ReportTransfer: React.FC<Props> = ({
  onChange,
  availableFields = [],
  includedFieldValues = []
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const previousSelectedKeys: string[] = usePrevious(selectedKeys) || [];

  useEffect(() => {
    // These fields must be added/removed as a pair.
    const pairedKeys = ["ACCOUNTINGCODE", "ACCOUNTING_CODE_AMOUNT"];

    // If one is there, determine whether to add or remove the other one.
    const selectedPairedKeys = intersection(selectedKeys, pairedKeys);
    if (selectedPairedKeys.length === 1) {
      if (previousSelectedKeys.includes(selectedPairedKeys[0])) {
        setSelectedKeys(selectedKeys.filter(key => !pairedKeys.includes(key)));
      } else {
        setSelectedKeys(union(selectedKeys, pairedKeys));
      }
    }
  }, [previousSelectedKeys, selectedKeys]);

  return (
    <ReactDragListView
      onDragEnd={(fromIndex, toIndex) => {
        const fieldsToMove = includedFieldValues.filter(
          (field, index) => index === fromIndex || selectedKeys.includes(field)
        );

        const fieldsToStay = includedFieldValues.filter(
          field => !fieldsToMove.includes(field)
        );

        onChange([
          ...fieldsToStay.slice(0, toIndex),
          ...fieldsToMove,
          ...fieldsToStay.slice(toIndex)
        ]);
      }}
      nodeSelector=".ant-transfer-list:last-child .ant-transfer-list-content > li"
      lineClassName="ReportTransfer-dragLine"
    >
      <Transfer
        className="ReportTransfer-transfer"
        showSelectAll={false}
        dataSource={reportFieldsToTransferItems(availableFields)}
        render={item => renderTransferItem(item)}
        locale={{
          itemUnit: "field",
          itemsUnit: "fields"
        }}
        onChange={keys => onChange(keys)}
        onSelectChange={(sourceSelectedKeys, targetSelectedKeys) =>
          setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
        }
        targetKeys={includedFieldValues}
        selectedKeys={selectedKeys}
        filterOption={(value, option) =>
          option.title?.toLowerCase().includes(value.toLowerCase()) || false
        }
        showSearch
        disabled={availableFields.length === 0}
      />
    </ReactDragListView>
  );
};

export default ReportTransfer;
