export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `DateTime` scalar type
   * represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTime: any,
  /** 
 * The `Date` scalar type
   * represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  Date: any,
  Decimal: any,
  /** 
 * The `DateTimeOffset` scalar type
   * represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTimeOffset: any,
  /** 
 * The `Seconds` scalar type
   * represents a period of time represented as the total number of seconds.
 */
  Seconds: any,
  /** 
 * The `Milliseconds` scalar type
   * represents a period of time represented as the total number of milliseconds.
 */
  Milliseconds: any,
};

export type Address = {
   __typename?: 'Address',
  city: Scalars['String'],
  country: Scalars['String'],
  postalCode?: Maybe<Scalars['String']>,
  province?: Maybe<Scalars['String']>,
  street1: Scalars['String'],
  street2?: Maybe<Scalars['String']>,
};

export enum ApplicationContext {
  Ap = 'AP',
  Reporting = 'REPORTING'
}

export type BackgroundJob = {
   __typename?: 'BackgroundJob',
  dateCreated: Scalars['DateTime'],
  dateModified: Scalars['DateTime'],
  displayName: Scalars['String'],
  id: Scalars['String'],
  state?: Maybe<BackgroundJobState>,
};

export enum BackgroundJobState {
  Unknown = 'UNKNOWN',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type Branch = {
   __typename?: 'Branch',
  address?: Maybe<Address>,
  contact?: Maybe<Contact>,
  key: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  region?: Maybe<Region>,
};

export type Contact = {
   __typename?: 'Contact',
  email?: Maybe<Scalars['String']>,
  fax?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
};

export type Corporation = {
   __typename?: 'Corporation',
  baseCurrency?: Maybe<Scalars['String']>,
  key: Scalars['Int'],
  name: Scalars['String'],
};

export type Customer = {
   __typename?: 'Customer',
  baseCurrency: Scalars['String'],
  closeLevel: Scalars['String'],
  contact?: Maybe<Contact>,
  corporation?: Maybe<Corporation>,
  divisions?: Maybe<Array<Maybe<Division>>>,
  fundingType?: Maybe<FundingType>,
  key: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  owner: Scalars['String'],
};


export type DateArgument = {
  greaterThan?: Maybe<Scalars['Date']>,
  greaterThanOrEqual?: Maybe<Scalars['Date']>,
  lessThan?: Maybe<Scalars['Date']>,
  lessThanOrEqual?: Maybe<Scalars['Date']>,
  equal?: Maybe<Scalars['Date']>,
};

export enum DateRangeType {
  Specific = 'SPECIFIC',
  WeekMf = 'WEEK_MF',
  WeekMs = 'WEEK_MS',
  Monthly = 'MONTHLY'
}




export type DeleteReportInput = {
  reportId: Scalars['Int'],
};

export type DeleteSearchInput = {
  id: Scalars['Int'],
};

export type Division = {
   __typename?: 'Division',
  address?: Maybe<Address>,
  contact?: Maybe<Contact>,
  customer?: Maybe<Customer>,
  description?: Maybe<Scalars['String']>,
  key: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
  regions?: Maybe<Array<Maybe<Region>>>,
};

export type Export = {
   __typename?: 'Export',
  url: Scalars['String'],
};

export enum FundingType {
  Funded = 'FUNDED',
  NonFunded = 'NON_FUNDED'
}

export type Invoice = {
   __typename?: 'Invoice',
  number: Scalars['String'],
};


export type Money = {
   __typename?: 'Money',
  amount: Scalars['Decimal'],
  currencyCode?: Maybe<Scalars['String']>,
};

export type Payment = {
   __typename?: 'Payment',
  amount?: Maybe<Money>,
  branch?: Maybe<Branch>,
  closeoutId?: Maybe<Scalars['String']>,
  customer?: Maybe<Customer>,
  date?: Maybe<Scalars['Date']>,
  invoice?: Maybe<Invoice>,
  number?: Maybe<Scalars['String']>,
  paymentRequests?: Maybe<Array<Maybe<PaymentRequest>>>,
  shipments?: Maybe<Array<Maybe<Shipment>>>,
  type?: Maybe<PaymentType>,
};


export type PaymentPaymentRequestsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type PaymentShipmentsArgs = {
  limit?: Maybe<Scalars['Int']>
};

export type PaymentRequest = {
   __typename?: 'PaymentRequest',
  amount?: Maybe<Money>,
  branch?: Maybe<Branch>,
  date?: Maybe<Scalars['Date']>,
  key: Scalars['String'],
  open?: Maybe<Scalars['Boolean']>,
  payment?: Maybe<Payment>,
  shipments?: Maybe<Array<Maybe<Shipment>>>,
};

export enum PaymentType {
  Ach = 'ACH',
  Check = 'CHECK',
  VCard = 'V_CARD'
}

export type Region = {
   __typename?: 'Region',
  address?: Maybe<Address>,
  branches?: Maybe<Array<Maybe<Branch>>>,
  contact?: Maybe<Contact>,
  description?: Maybe<Scalars['String']>,
  division?: Maybe<Division>,
  key: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
};

export type Report = {
   __typename?: 'Report',
  conditions?: Maybe<Array<ReportCondition>>,
  createdBy: User,
  currencyOverride?: Maybe<Scalars['String']>,
  currencyType: ReportCurrencyType,
  customerOrCorporationKey: Scalars['Int'],
  dateCreated: Scalars['DateTime'],
  dateModified: Scalars['DateTime'],
  dateRangeType: DateRangeType,
  dateType: ReportDateType,
  endDate?: Maybe<Scalars['Date']>,
  fields: Array<Scalars['String']>,
  groupByFields?: Maybe<Array<Scalars['String']>>,
  keyType: ReportKeyType,
  name?: Maybe<Scalars['String']>,
  reportId: Scalars['Int'],
  schedule?: Maybe<Schedule>,
  sharedWith: Array<User>,
  sortByFields?: Maybe<Array<Scalars['String']>>,
  startDate?: Maybe<Scalars['Date']>,
};

export type ReportCondition = {
   __typename?: 'ReportCondition',
  fieldId: Scalars['String'],
  id?: Maybe<Scalars['String']>,
  operand: ReportConditionOperand,
  operator: ReportConditionOperator,
  value: Array<Scalars['String']>,
};

export type ReportConditionInput = {
  fieldId: Scalars['String'],
  operand: ReportConditionOperand,
  operator: ReportConditionOperator,
  value: Array<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
};

export enum ReportConditionOperand {
  And = 'AND',
  Or = 'OR'
}

export enum ReportConditionOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  IsGreaterThan = 'IS_GREATER_THAN',
  IsGreaterThanOrEqual = 'IS_GREATER_THAN_OR_EQUAL',
  IsLessThan = 'IS_LESS_THAN',
  IsLessThanOrEqual = 'IS_LESS_THAN_OR_EQUAL',
  IsInRange = 'IS_IN_RANGE',
  IsInList = 'IS_IN_LIST',
  IsNotInList = 'IS_NOT_IN_LIST',
  Contains = 'CONTAINS'
}

export enum ReportCurrencyType {
  CustomerCurrency = 'CUSTOMER_CURRENCY',
  BilledCurrency = 'BILLED_CURRENCY'
}

export enum ReportDateType {
  ShipDate = 'SHIP_DATE',
  EntryDate = 'ENTRY_DATE',
  PaidDate = 'PAID_DATE'
}

export type ReportField = {
   __typename?: 'ReportField',
  dataType: ReportFieldDataType,
  isSummarizable: Scalars['Boolean'],
  label: Scalars['String'],
  specialtyType: ReportFieldSpecialtyType,
  value: Scalars['String'],
};

export enum ReportFieldDataType {
  Number = 'NUMBER',
  Money = 'MONEY',
  Text = 'TEXT',
  Date = 'DATE'
}

export enum ReportFieldSpecialtyType {
  Normal = 'NORMAL',
  Udf = 'UDF',
  PackageUdf = 'PACKAGE_UDF',
  Special = 'SPECIAL'
}

export type ReportInput = {
  name?: Maybe<Scalars['String']>,
  reportId?: Maybe<Scalars['Int']>,
  customerOrCorporationKey: Scalars['Int'],
  keyType: ReportKeyType,
  dateType: ReportDateType,
  fields: Array<Scalars['String']>,
  currencyType: ReportCurrencyType,
  currencyOverride?: Maybe<Scalars['String']>,
  conditions?: Maybe<Array<ReportConditionInput>>,
  sortByFields?: Maybe<Array<Maybe<Scalars['String']>>>,
  groupByFields?: Maybe<Array<Maybe<Scalars['String']>>>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  dateRangeType?: Maybe<DateRangeType>,
  schedule?: Maybe<ScheduleInput>,
  sharedWith?: Maybe<Array<UserInput>>,
};

export enum ReportKeyType {
  Customer = 'CUSTOMER',
  Corporation = 'CORPORATION'
}

export type RootGraphQlMutation = {
   __typename?: 'RootGraphQLMutation',
  createReport?: Maybe<Report>,
  createSearch?: Maybe<Search>,
  deleteReport?: Maybe<Report>,
  deleteSearch?: Maybe<Search>,
  updateReport?: Maybe<Report>,
  updateSearch?: Maybe<Search>,
};


export type RootGraphQlMutationCreateReportArgs = {
  report?: Maybe<ReportInput>
};


export type RootGraphQlMutationCreateSearchArgs = {
  search?: Maybe<SearchInput>
};


export type RootGraphQlMutationDeleteReportArgs = {
  report?: Maybe<DeleteReportInput>
};


export type RootGraphQlMutationDeleteSearchArgs = {
  search?: Maybe<DeleteSearchInput>
};


export type RootGraphQlMutationUpdateReportArgs = {
  report?: Maybe<ReportInput>
};


export type RootGraphQlMutationUpdateSearchArgs = {
  search?: Maybe<SearchInput>
};

export type RootGraphQlQuery = {
   __typename?: 'RootGraphQLQuery',
  backgroundJobs: Array<Maybe<BackgroundJob>>,
  customers?: Maybe<Array<Customer>>,
  exports: Export,
  payments?: Maybe<Array<Payment>>,
  reportFields: Array<ReportField>,
  reportFieldValues: StringArrayResult,
  reports?: Maybe<Array<Report>>,
  runReport: RunReport,
  searches?: Maybe<Array<Search>>,
  users?: Maybe<Array<User>>,
};


export type RootGraphQlQueryCustomersArgs = {
  context: ApplicationContext,
  customerKey?: Maybe<Scalars['Int']>
};


export type RootGraphQlQueryExportsArgs = {
  query?: Maybe<Scalars['String']>
};


export type RootGraphQlQueryPaymentsArgs = {
  date?: Maybe<DateArgument>,
  number?: Maybe<StringArgument>,
  includedTypes?: Maybe<Array<Maybe<PaymentType>>>,
  customerKey?: Maybe<Scalars['Int']>,
  branchKey?: Maybe<Scalars['Int']>,
  divisionKey?: Maybe<Scalars['Int']>,
  regionKey?: Maybe<Scalars['Int']>,
  carrierKey?: Maybe<Scalars['Int']>,
  fundingType?: Maybe<FundingType>,
  customerCloseLevel?: Maybe<Array<Maybe<Scalars['String']>>>,
  limit?: Maybe<Scalars['Int']>,
  transportationProviderShortName?: Maybe<StringArgument>
};


export type RootGraphQlQueryReportFieldsArgs = {
  customerOrCorporationKey: Scalars['Int'],
  keyType: ReportKeyType
};


export type RootGraphQlQueryReportFieldValuesArgs = {
  fieldId: Scalars['String'],
  keyType: ReportKeyType,
  customerOrCorporationKey: Scalars['Int'],
  dateType: ReportDateType,
  dateRange: DateArgument
};


export type RootGraphQlQueryReportsArgs = {
  id?: Maybe<Scalars['Int']>
};


export type RootGraphQlQueryRunReportArgs = {
  report?: Maybe<ReportInput>
};


export type RootGraphQlQuerySearchesArgs = {
  id?: Maybe<Scalars['Int']>
};


export type RootGraphQlQueryUsersArgs = {
  customerOrCorporationKey: Scalars['Int'],
  keyType: ReportKeyType
};

export type RunReport = {
   __typename?: 'RunReport',
  hasBeenQueued: Scalars['Boolean'],
  urls: Array<Scalars['String']>,
};

export type Schedule = {
   __typename?: 'Schedule',
  cronSchedule?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  isActive: Scalars['Boolean'],
  lastRunDate?: Maybe<Scalars['Date']>,
  message?: Maybe<Scalars['String']>,
  recipients: Array<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
  timeZone?: Maybe<Scalars['String']>,
};

export type ScheduleInput = {
  id?: Maybe<Scalars['Int']>,
  isActive: Scalars['Boolean'],
  cronSchedule?: Maybe<Scalars['String']>,
  timeZone?: Maybe<Scalars['String']>,
  recipients: Array<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
};

export type Search = {
   __typename?: 'Search',
  baseSearch: Scalars['String'],
  conditions?: Maybe<Scalars['String']>,
  createdBy?: Maybe<User>,
  dateCreated: Scalars['DateTime'],
  dateModified: Scalars['DateTime'],
  id: Scalars['Int'],
  name: Scalars['String'],
  query: Scalars['String'],
  schedule?: Maybe<Schedule>,
};

export type SearchInput = {
  id?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  baseSearch: Scalars['String'],
  query: Scalars['String'],
  conditions?: Maybe<Scalars['String']>,
  schedule?: Maybe<ScheduleInput>,
};


export type Shipment = {
   __typename?: 'Shipment',
  billedAmount?: Maybe<Money>,
  branch?: Maybe<Branch>,
  dateReceived?: Maybe<Scalars['DateTime']>,
  key: Scalars['Int'],
  number: Scalars['String'],
  paidAmount?: Maybe<Money>,
  paymentNumber?: Maybe<Scalars['String']>,
  status?: Maybe<Status>,
  transportationProvider?: Maybe<TransportationProvider>,
};

export type Status = {
   __typename?: 'Status',
  code: Scalars['String'],
  description: Scalars['String'],
};

export type StringArgument = {
  startsWith?: Maybe<Scalars['String']>,
  endsWith?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  equal?: Maybe<Scalars['String']>,
};

export type StringArrayResult = {
   __typename?: 'StringArrayResult',
  result?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type TransportationProvider = {
   __typename?: 'TransportationProvider',
  key: Scalars['Int'],
  name?: Maybe<Scalars['String']>,
};

export type User = {
   __typename?: 'User',
  dateCreated: Scalars['DateTime'],
  dateModified: Scalars['DateTime'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  firstName?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  lastName?: Maybe<Scalars['String']>,
};

export type UserInput = {
  id: Scalars['Int'],
  displayName?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

