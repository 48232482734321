import { InputNumber } from "antd";
import React from "react";
import "./NumberRange.less";

interface Props {
  min?: number;
  max?: number;
  onChange: (min?: number, max?: number) => void;
}

const NumberRange: React.FC<Props> = ({ min, max, onChange }) => {
  const handleChange = (portion: "min" | "max", value?: number | string) => {
    let newValue: number | undefined = value as number;

    // Due to a bug in InputNumber, it may return an empty string.
    // https://github.com/ant-design/ant-design/issues/12795
    if (value === "") {
      newValue = undefined;
    }

    portion === "min" ? onChange(newValue, max) : onChange(min, newValue);
  };

  return (
    <div className="NumberRange">
      <InputNumber
        onChange={value => handleChange("min", value)}
        className="NumberRange-min"
        value={min}
        type="number"
      />
      <div className="NumberRange-separator">-</div>
      <InputNumber
        onChange={value => handleChange("max", value)}
        className="NumberRange-max"
        value={max}
        type="number"
      />
    </div>
  );
};

export default NumberRange;
