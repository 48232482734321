import { Report } from "types/graphql";
import { useStoreState } from "./storeHooks";

const usePermissions = () => {
  const user = useStoreState(state => state.auth.user);
  const groups = useStoreState(state => state.auth.groups);

  const canAccessSearches = groups?.includes("AccountPayable");

  const canAccessReports = groups?.includes("iFocusOnDemands");

  const canEditReport = (report?: Partial<Report>) =>
    report?.createdBy?.id === user?.id;

  return { canAccessSearches, canAccessReports, canEditReport };
};

export default usePermissions;
