import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ITelemetryPlugin
} from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { getStoredUser } from "state/authModel";

export type AppInsightsTrackFn = (
  name: string,
  properties?: { [key: string]: any }
) => void;

export default class AppInsightsService {
  ai: ApplicationInsights;

  constructor() {
    const browserHistory = createBrowserHistory({ basename: "" });
    const reactPlugin = new ReactPlugin();

    this.ai = new ApplicationInsights({
      config: {
        instrumentationKey: "e91bddbb-e3ff-4508-9114-362b9bb322b0",
        extensions: [reactPlugin as ITelemetryPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        },
        enableAutoRouteTracking: true
      }
    });
    this.ai.loadAppInsights();

    const user = getStoredUser();
    this.ai.setAuthenticatedUserContext(
      user?.username || "Unknown username",
      user?.id?.toString(),
      true
    );
  }

  get appInsights() {
    return this.ai.appInsights;
  }

  track: AppInsightsTrackFn = (name, properties) => {
    this.appInsights.trackEvent({ name }, properties);
  };
}
