import { message } from "antd";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { IMPACT_API_PATH } from "config";
import AppInsightsService from "services/AppInsightsService";
import { AuthUser } from "state/authModel";

const NOT_AUTHENTICATED_ERROR = "User not authenticated.";

const client = (
  appInsightsService: AppInsightsService,
  signOut: (user: AuthUser) => void,
  user?: AuthUser
) => {
  const authorizedRequest = async (operation: any) => {
    const token = user?.token;

    if (!token) {
      throw new Error(NOT_AUTHENTICATED_ERROR);
    }

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      message.error("Database error, please try again.");
      graphQLErrors.forEach(error =>
        appInsightsService.appInsights.trackException({ exception: error })
      );
    }
    if (networkError) {
      if ("statusCode" in networkError && networkError.statusCode === 401) {
        user && signOut(user);
      } else if (networkError.message !== NOT_AUTHENTICATED_ERROR) {
        message.error("Network error, please try again.");
        appInsightsService.appInsights.trackException({
          exception: networkError
        });
      }
    }
  });

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: any;
        Promise.resolve(operation)
          .then(operation => authorizedRequest(operation))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const httpLink = new HttpLink({
    uri: IMPACT_API_PATH
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, requestLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });
};

export default client;
