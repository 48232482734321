import { createTypedHooks } from "easy-peasy";
import { AppModel } from "../state/appModel";

const {
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore
} = createTypedHooks<AppModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };
