import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popconfirm, Tabs, Tooltip } from "antd";
import UserPicker from "components/UserPicker/UserPicker";
import { useStoreActions, useStoreState } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import "./ReportSchedule.less";
import Scheduler from "./Scheduler";

const DEFAULT_SCHEDULE = "0 17 * * 5";

const ReportSchedule = () => {
  const report = useStoreState(state => state.reports.report);
  const isAdHoc = useStoreState(state => state.reports.reportAdHoc);
  const setReport = useStoreActions(actions => actions.reports.setReport);
  const [cronSchedule, setCronSchedule] = useState(DEFAULT_SCHEDULE);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const hasSchedule = !!report?.schedule;

  useEffect(() => {
    setCronSchedule(report.schedule?.cronSchedule || DEFAULT_SCHEDULE);
    setRecipients(report.schedule?.recipients || []);
    setSubject(report.schedule?.subject || "");
    setMessage(report.schedule?.message || "");
  }, [report]);

  const removeSchedule = () => {
    // Revert to the default values for next time.
    setCronSchedule(DEFAULT_SCHEDULE);
    setRecipients([]);
    setSubject("");
    setMessage("");

    setReport({ ...report, schedule: null });
    setModalOpen(false);
  };

  const commitSchedule = () => {
    setReport({
      ...report,
      schedule: {
        id: 0, // Required by the type, but ignored when creating/updating.
        isActive: true, // Always set to true for now (potential feature for later).
        cronSchedule,
        recipients,
        subject,
        message
      }
    });
    setModalOpen(false);
  };

  return (
    <>
      <Tooltip title={isAdHoc && "Only saved reports can be scheduled."}>
        <Button
          type="link"
          icon={<ClockCircleOutlined />}
          onClick={() => setModalOpen(true)}
          disabled={
            !report?.keyType || !report.customerOrCorporationKey || isAdHoc
          }
        >
          {hasSchedule ? "Edit Schedule" : "Schedule"}
        </Button>
      </Tooltip>
      <Modal
        className="ReportSchedule-modal"
        visible={modalOpen}
        maskClosable={true}
        destroyOnClose
        onCancel={() => setModalOpen(false)}
        footer={[
          hasSchedule && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={removeSchedule}
              okText="Remove"
              cancelText="Cancel"
              okType="danger"
              key="remove"
            >
              <Button
                type="link"
                danger
                className="ReportSchedule-modal-removeButton"
              >
                Remove schedule
              </Button>
            </Popconfirm>
          ),
          <Button key="cancel" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>,
          <Tooltip
            key="schedule"
            placement="top"
            title={
              recipients.length === 0 && "Email needs at least one recipient."
            }
          >
            <div>
              <Button
                type="primary"
                onClick={commitSchedule}
                disabled={recipients.length === 0}
              >
                {hasSchedule ? "Update schedule" : "Schedule"}
              </Button>
            </div>
          </Tooltip>
        ]}
      >
        <p>
          <strong>Schedule Report</strong>
        </p>
        <p>
          Set a schedule to run this report automatically on a regular basis,
          with the results sent out by email.
        </p>
        <Tabs defaultActiveKey="schedule" size="small">
          <Tabs.TabPane tab="Schedule" key="schedule">
            <Scheduler schedule={cronSchedule} onChange={setCronSchedule} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Email" key="email">
            <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
              <Form.Item label="Recipients">
                <UserPicker
                  customerOrCorporationKey={report?.customerOrCorporationKey}
                  customerOrCorporationKeyType={report?.keyType}
                  onChange={users => setRecipients(users.map(u => u.email))}
                  selectedEmails={recipients}
                  onlyAllowKnownEmails={false}
                />
              </Form.Item>
              <Form.Item label="Subject">
                <Input
                  maxLength={60}
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Message">
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default ReportSchedule;
