import { DatePicker, Select } from "antd";
import { DATE_DISPLAYED, DATE_STORED } from "data/formats";
import moment from "moment";
import React from "react";
import { DateRangeType } from "types/graphql";
import "./DateRangePicker.less";

interface Props {
  rangeType: DateRangeType | undefined;
  startDate: string;
  endDate: string;
  onChange: (
    rangeType: DateRangeType,
    startDate?: string,
    endDate?: string
  ) => void;
}

const DateRangePicker: React.FC<Props> = ({
  rangeType,
  startDate,
  endDate,
  onChange
}) => {
  const handleSelectChange = (rangeType: DateRangeType) => {
    if (rangeType !== DateRangeType.Specific) {
      onChange(rangeType);
    } else {
      onChange(rangeType, startDate, endDate);
    }
  };

  return (
    <div className="DateRangePicker">
      <Select
        value={rangeType}
        placeholder="Select date range..."
        onChange={handleSelectChange}
      >
        <Select.Option value={DateRangeType.WeekMf}>
          Previous 5-day week (Mon - Fri)
        </Select.Option>
        <Select.Option value={DateRangeType.WeekMs}>
          Previous 7-day week (Mon - Sun)
        </Select.Option>
        <Select.Option value={DateRangeType.Monthly}>
          Previous month
        </Select.Option>
        <Select.Option value={DateRangeType.Specific}>
          Specific date range
        </Select.Option>
      </Select>
      {rangeType === DateRangeType.Specific && (
        <DatePicker.RangePicker
          allowClear={false}
          value={[moment(startDate), moment(endDate)]}
          onChange={dates =>
            onChange(
              rangeType,
              dates?.[0]?.format(DATE_STORED),
              dates?.[1]?.format(DATE_STORED)
            )
          }
          disabledDate={date => date.isAfter()}
          format={DATE_DISPLAYED}
        />
      )}
    </div>
  );
};

export default DateRangePicker;
