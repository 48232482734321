import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import useCreateSearch from "hooks/useCreateSearch";
import React, { useState } from "react";
import { BaseSearch, Condition } from "types/search";

interface Props {
  baseSearch: BaseSearch<any>;
  conditions: Condition[];
}

const DuplicateSearch: React.FC<Props> = ({ baseSearch, conditions }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [createSearch, loading, error] = useCreateSearch({
    name: searchName,
    baseSearch: baseSearch.name,
    conditions
  });

  return (
    <>
      <Button
        type="link"
        icon={<CopyOutlined />}
        onClick={() => setModalOpen(true)}
      >
        Duplicate
      </Button>
      <Modal
        visible={modalOpen}
        onOk={createSearch}
        onCancel={() => setModalOpen(false)}
        maskClosable={false}
        okText={loading ? "Creating search" : "Create search"}
        okButtonProps={{ disabled: !searchName, loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <p>
          <strong>Duplicate search</strong>
        </p>
        <p>
          This will create a copy of the current search. While anyone can view
          it, only you can make changes.
        </p>
        <Input
          placeholder="Name of new search"
          onChange={event => setSearchName(event.target.value)}
          value={searchName}
          ref={input => input && input.focus()}
        />
        {error}
      </Modal>
    </>
  );
};

export default DuplicateSearch;
