import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Maybe,
  ReportConditionOperand,
  ReportConditionOperator
} from "types/graphql";
import { Condition, Field } from "types/search";
import "./ConditionBuilder.less";
import ConditionBuilderCondition from "./ConditionBuilderCondition/ConditionBuilderCondition";

const validCondition = (condition: Condition) => {
  // Must have a value.
  if (
    condition.value === undefined ||
    condition.value === null ||
    condition.value === ""
  ) {
    return false;
  }

  // If it's an array, it can't be an empty.
  if (Array.isArray(condition.value) && condition.value.length === 0) {
    return false;
  }

  // If it's a range, there must a value for both the min and max and the max must be greater.
  if (
    condition.operator === ReportConditionOperator.IsInRange &&
    (condition.value[0] === undefined ||
      condition.value[1] === undefined ||
      condition.value[0] > condition.value[1])
  ) {
    return false;
  }

  return true;
};

const getValidConditions = (conditions: Condition[]) =>
  conditions.filter(validCondition);

interface Props {
  fields: Field[];
  conditions: Condition[];
  onConditionsChange: (conditions: Condition[]) => void;
  showOperands?: boolean;
  allowRepeatedFields?: boolean;
  fieldListValueRetrievalFunc?: (fieldId: string) => Promise<Maybe<string>[]>;
}

const ConditionBuilder: React.FC<Props> = ({
  fields,
  conditions,
  onConditionsChange,
  fieldListValueRetrievalFunc,
  showOperands = false,
  allowRepeatedFields = false
}) => {
  const [allConditions, setAllConditions] = useState<Condition[]>([]);
  const availableFields = fields.filter(
    field => !conditions.find(condition => condition.field === field.id)
  );

  // Whenever conditions come in, merge them with internal state.
  useEffect(() => {
    const merged = _.unionBy(conditions, allConditions, "id");
    const hasChanged = !_.isEqual(merged, allConditions);

    hasChanged && setAllConditions(merged);
  }, [conditions, allConditions]);

  const createCondition = () => {
    const field = availableFields[0];
    const operator = field.operators[0];

    const updatedConditions = [
      ...allConditions,
      {
        id: Guid.raw(),
        field: field.id,
        operand: ReportConditionOperand.And,
        operator: operator.id,
        value: operator?.defaultValue
      }
    ];
    updateConditions(updatedConditions);
  };

  const updateCondition = (updatedCondition: Condition) => {
    const updatedConditions = allConditions.map(condition =>
      condition.id === updatedCondition.id ? updatedCondition : condition
    );
    updateConditions(updatedConditions);
  };

  const deleteCondition = (deletedCondition: Condition) => {
    let updatedConditions = allConditions.filter(
      condition => condition.id !== deletedCondition.id
    );
    updateConditions(updatedConditions);
  };

  const updateConditions = (conditions: Condition[]) => {
    setAllConditions(conditions);
    onConditionsChange(getValidConditions(conditions));
  };

  return (
    <div className="ConditionBuilder">
      <ul className="ConditionBuilder-conditions">
        {allConditions.map((condition, index) => (
          <li
            key={condition.id.toString()}
            className="ConditionBuilder-conditions-condition"
          >
            <ConditionBuilderCondition
              key={condition.id.toString()}
              onConditionUpdate={updateCondition}
              onConditionDelete={deleteCondition}
              fields={fields}
              availableFields={availableFields}
              fieldValueListRetrievalFunc={fieldListValueRetrievalFunc}
              condition={condition}
              showOperand={showOperands && index !== 0}
              allowRepeatedFields={allowRepeatedFields}
              isInvalid={!validCondition(condition)}
            />
          </li>
        ))}
      </ul>

      <Button
        icon={<PlusOutlined />}
        onClick={createCondition}
        disabled={!fields || fields.length === 0}
      >
        Add New Condition
      </Button>
    </div>
  );
};

export default ConditionBuilder;
