import classNames from "classnames";
import Footer from "components/Footer/Footer";
import React from "react";
import "./Page.less";

interface Props {
  className?: string;
}

const Page: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames("Page", className)}>
      {children}
      <Footer />
    </div>
  );
};

export default Page;
