import { ColumnProps } from "antd/lib/table";
import { DATE_DISPLAYED, DATE_STORED } from "data/formats";
import moment from "moment";
import { Payment } from "types/graphql";
import { Condition, Field } from "types/search";

export type SharedColumns =
  | "amount"
  | "customerName"
  | "date"
  | "invoiceNumber"
  | "number";

export const sharedFields: Field[] = [
  {
    id: "date",
    name: "Date",
    operators: [
      {
        id: "is",
        name: "is",
        type: "date",
        defaultValue: moment().format(DATE_STORED)
      },
      {
        id: "between",
        name: "between",
        type: "dateRange",
        defaultValue: [
          moment()
            .subtract(1, "week")
            .format(DATE_STORED),
          moment().format(DATE_STORED)
        ]
      }
    ]
  },
  {
    id: "paymentNumber",
    name: "Payment number",
    operators: [
      {
        id: "is",
        name: "is",
        type: "text"
      }
    ]
  },
  {
    id: "includedTypes",
    name: "Payment type",
    operators: [
      {
        id: "isOneOf",
        name: "is one of",
        type: "optionArray"
      }
    ],
    options: [
      {
        label: "Check",
        value: "CHECK"
      },
      {
        label: "ACH",
        value: "ACH"
      },
      {
        label: "vCard",
        value: "V_CARD"
      }
    ]
  },
  {
    id: "transportationProviderShortName",
    name: "Transportation provider short name",
    operators: [
      {
        id: "is",
        name: "is",
        type: "text"
      }
    ]
  },
  {
    id: "customer",
    name: "Customer",
    operators: [
      {
        id: "is",
        name: "is",
        type: "customer"
      }
    ]
  },
  {
    id: "division",
    name: "Division",
    operators: [
      {
        id: "is",
        name: "is",
        type: "division"
      }
    ]
  },
  {
    id: "region",
    name: "Region",
    operators: [
      {
        id: "is",
        name: "is",
        type: "region"
      }
    ]
  },
  {
    id: "branch",
    name: "Branch",
    operators: [
      {
        id: "is",
        name: "is",
        type: "branch"
      }
    ]
  }
];

export const sharedArgs = (
  conditions: Condition[],
  report: boolean = false
): string[] => {
  const args: string[] = [];

  // Unless we're getting data for a report, limit it
  // to 100 results to avoid UI performance issues. This
  // can be removed if/when we implement pagination.
  if (!report) {
    args.push("limit: 100");
  }

  const date = conditions.find(condition => condition.field === "date")?.value;
  if (date && !Array.isArray(date)) {
    args.push(` date: { equal: "${moment(date).format(DATE_STORED)}" }`);
  }
  if (date && Array.isArray(date)) {
    args.push(`
      date: {
        greaterThanOrEqual: "${moment(date[0]).format(DATE_STORED)}",
        lessThanOrEqual: "${moment(date[1]).format(DATE_STORED)}"
      }
    `);
  }

  const paymentNumber = conditions.find(
    condition => condition.field === "paymentNumber"
  )?.value;
  if (paymentNumber) {
    args.push(`number: { equal: "${paymentNumber}" }`);
  }

  const includedTypes = conditions.find(
    condition => condition.field === "includedTypes"
  )?.value as string[];
  if (includedTypes && includedTypes.length > 0) {
    args.push(`includedTypes: [${includedTypes.join(", ")}]`);
  }

  const transportationProviderShortName = conditions.find(
    condition => condition.field === "transportationProviderShortName"
  )?.value;
  if (transportationProviderShortName) {
    args.push(
      `transportationProviderShortName: { equal: "${transportationProviderShortName}" }`
    );
  }

  const customer = conditions.find(condition => condition.field === "customer");
  if (customer?.value !== undefined) {
    args.push(`customerKey: ${customer.value}`);
  }

  const division = conditions.find(condition => condition.field === "division");
  if (division?.value !== undefined) {
    args.push(`divisionKey: ${division.value.key}`);
  }

  const region = conditions.find(condition => condition.field === "region");
  if (region?.value !== undefined) {
    args.push(`regionKey: ${region.value.key}`);
  }

  const branch = conditions.find(condition => condition.field === "branch");
  if (branch?.value !== undefined) {
    args.push(`branchKey: ${branch.value.key}`);
  }

  return args;
};

export const resultToRowsShared = (payment: Payment) => ({
  id: `${payment.number}_${payment.invoice?.number}`,
  number: payment.number ?? "",
  date: payment.date,
  amount: payment.amount?.amount ?? "",
  customerName: payment.branch?.region?.division?.customer?.name ?? "",
  invoiceNumber: payment.invoice?.number ?? ""
});

export const sharedResultColumns: ColumnProps<any>[] = [
  {
    key: "number",
    dataIndex: "number",
    title: "Number"
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    render: (text: string) => moment(text).format(DATE_DISPLAYED)
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "Amount"
  },
  {
    key: "customerName",
    dataIndex: "customerName",
    title: "Customer name"
  },
  {
    key: "invoiceNumber",
    dataIndex: "invoiceNumber",
    title: "Invoice number"
  }
];
